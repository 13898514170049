var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c(
        "CButton",
        {
          class: "w-100",
          attrs: { variant: "outline", color: "ghost-button" },
          on: { click: _vm.openNotificationModal },
        },
        [_vm._v(" " + _vm._s(_vm.$t("lk.header.notifications")) + " ")]
      ),
      _vm.renderNotificationModal
        ? _c("TrendsNotificationModalv2", {
            ref: "trendsNotificationModal",
            attrs: {
              loading: _vm.loading,
              "modal-notification-open": _vm.showNotificationModal,
              trendsNotify: _vm.trendsNotify,
            },
            on: {
              close: _vm.handleCloseNotificationModal,
              saveNotification: _vm.handleSaveNotification,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }