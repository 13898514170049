var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CRow",
    {
      staticClass: "custom-row no-gutters",
      class: { "_no-select": _vm.selectShiftActive },
    },
    [
      _c(
        "CCol",
        { staticClass: "custom-col", attrs: { col: "12" } },
        [
          _c(
            "CCard",
            {
              staticClass: "overflow-auto mb-0 h-100",
              attrs: { color: "base-card-bg" },
            },
            [
              _c("CCardBody", { staticClass: "overflow-auto" }, [
                _vm.items.length
                  ? _c(
                      "div",
                      { staticClass: "overflow-auto h-100 table-wrap" },
                      [
                        _c("q-virtual-scroll", {
                          ref: "virtualListRef",
                          staticClass: "table",
                          attrs: {
                            type: "table",
                            "virtual-scroll-item-size":
                              _vm.virtualScrollItemSize,
                            items: _vm.items,
                            "scroll-target": ".table-wrap",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "before",
                                fn: function () {
                                  return [
                                    _c("thead", [
                                      _c(
                                        "tr",
                                        [
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "reset",
                                                  on: {
                                                    click: _vm.resetSorting,
                                                  },
                                                },
                                                [
                                                  _c("CIcon", {
                                                    attrs: {
                                                      width: "11px",
                                                      height: "11px",
                                                      name: "cil-x",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "table-cell-ticker",
                                                {
                                                  attrs: {
                                                    label: "Ticker",
                                                    "key-col": "ticker",
                                                  },
                                                },
                                                [
                                                  _c("watch-selector", {
                                                    attrs: {
                                                      "showed-rows": _vm
                                                        .activeRows.length
                                                        ? _vm.activeRows
                                                        : _vm.items,
                                                      isFutures:
                                                        _vm.$store.getters
                                                          .getLastScreen ===
                                                        "futures",
                                                      "popover-options": {
                                                        popoverClass:
                                                          "v-tooltip popover--header",
                                                      },
                                                    },
                                                  }),
                                                  _vm.filterIsActive &&
                                                  _vm.activeRows.length > 1
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "vs__deselect",
                                                          attrs: {
                                                            type: "button",
                                                            title: `${_vm.$t(
                                                              "general.deselect"
                                                            )}`,
                                                            "aria-label": `${_vm.$t(
                                                              "general.deselect"
                                                            )}`,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.removeSelectedPairs(
                                                                _vm.activeRows,
                                                                _vm.activeRows
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              attrs: {
                                                                xmlns:
                                                                  "http://www.w3.org/2000/svg",
                                                                width: "10",
                                                                height: "10",
                                                              },
                                                            },
                                                            [
                                                              _c("path", {
                                                                attrs: {
                                                                  d: "M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "th",
                                            [
                                              _c("table-cell-ticker", {
                                                attrs: {
                                                  label: "Last Update",
                                                  "key-col": "dateTimeUnix",
                                                  icon:
                                                    _vm.activeRows.length > 1
                                                      ? "bell"
                                                      : "",
                                                  "no-flag": "",
                                                },
                                                on: {
                                                  iconClick: function ($event) {
                                                    return _vm.handleClickNotificationBell(
                                                      _vm.reactiveActiveRows
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._l(
                                            _vm.percentsKeys,
                                            function (key, i) {
                                              return _c(
                                                "th",
                                                { key: i },
                                                [
                                                  _c("table-cell-header", {
                                                    key: key,
                                                    attrs: {
                                                      "key-col": key,
                                                      label:
                                                        _vm.reassignLabel[key],
                                                      percents:
                                                        _vm.percents[key],
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "default",
                                fn: function ({ item, index: rowIndex }) {
                                  return [
                                    _c(
                                      "tr",
                                      {
                                        key: rowIndex,
                                        ref: "row-table",
                                        class: item._classes,
                                        attrs: {
                                          "data-is-mobile": _vm.isMobile,
                                        },
                                        on: {
                                          animationstart: function ($event) {
                                            return _vm.startTable(rowIndex)
                                          },
                                          animationend: function ($event) {
                                            return _vm.animationEnd(item)
                                          },
                                          click: function ($event) {
                                            return _vm.selectRow(
                                              item,
                                              $event,
                                              _vm.items,
                                              _vm.activeRows
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "table-cell-ticker-content",
                                          {
                                            staticClass:
                                              "position-relative pl-4 tickerCell",
                                            attrs: { item: item },
                                          },
                                          [
                                            _c("watch-selector", {
                                              attrs: {
                                                ticker: item.ticker,
                                                isFutures:
                                                  _vm.$store.getters
                                                    .getLastScreen ===
                                                  "futures",
                                              },
                                              on: {
                                                removePair: function ($event) {
                                                  return _vm.removeSelectedPairs(
                                                    item,
                                                    _vm.activeRows
                                                  )
                                                },
                                              },
                                            }),
                                            _vm.filterIsActive
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass: "vs__deselect",
                                                    attrs: {
                                                      type: "button",
                                                      title: `${_vm.$t(
                                                        "general.deselect"
                                                      )}`,
                                                      "aria-label": `${_vm.$t(
                                                        "general.deselect"
                                                      )}`,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.removeSelectedPairs(
                                                          item,
                                                          _vm.activeRows,
                                                          true
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "svg",
                                                      {
                                                        attrs: {
                                                          xmlns:
                                                            "http://www.w3.org/2000/svg",
                                                          width: "10",
                                                          height: "10",
                                                        },
                                                      },
                                                      [
                                                        _c("path", {
                                                          attrs: {
                                                            d: "M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(
                                              " " + _vm._s(item.ticker) + " "
                                            ),
                                            _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: {
                                                      content:
                                                        _vm.$t(
                                                          "screener.openGraph"
                                                        ),
                                                      container: ".c-app",
                                                    },
                                                    expression:
                                                      "{\n                      content: $t('screener.openGraph'),\n                      container: '.c-app',\n                    }",
                                                  },
                                                ],
                                                staticClass:
                                                  "cil-bar-chart-btn",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    _vm.$refs.candles.openCandlesGraph(
                                                      `${item.ticker}USDT`.replace(
                                                        /(\w*)(\.P)?(\w*)/,
                                                        "$1$3$2"
                                                      ),
                                                      2,
                                                      null,
                                                      item.dateTimeUnix
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  [
                                                    _c("CIcon", {
                                                      staticClass:
                                                        "cursor-pointer",
                                                      attrs: {
                                                        width: "0.75rem",
                                                        name: "cil-bar-chart",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("td", [
                                          _c(
                                            "div",
                                            { staticClass: "update-time" },
                                            [
                                              _c("span", {}, [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.dateTimeStrDate
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "update-time__time",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.dateTimeStrTime
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "bell" },
                                                [
                                                  _vm.showBellButtons
                                                    ? _c("CIcon", {
                                                        staticClass:
                                                          "cil-bell-icon ml-1 flex-shrink-0",
                                                        class: item.notify
                                                          ? "_active"
                                                          : "_blocked",
                                                        attrs: { name: "bell" },
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.handleClickNotificationBell(
                                                              [item]
                                                            )
                                                          },
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._l(
                                          _vm.percentsKeys,
                                          function (key, i) {
                                            return _c("table-cell", {
                                              key: i,
                                              class: item._cellClasses[key],
                                              attrs: { value: item[key] },
                                            })
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1949179451
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("CandlesGraphModal", {
        ref: "candles",
        attrs: {
          rows: _vm.rows,
          settings: _vm.settings,
          "client-id": _vm.clientId,
          "is-futures": _vm.isActiveFutures,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }