var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "trendsHistory" }, [
    !_vm.loadingData && Object.keys(_vm.trendsHistoryData).length
      ? _c(
          "div",
          { staticClass: "trendsHistoryTables" },
          _vm._l(_vm.trendsHistoryData, function (item, key, index) {
            return _c(
              "div",
              { key: key, staticClass: "trendsHistoryTables__table" },
              [
                _c(
                  "div",
                  { staticClass: "trendsHistoryTables__table_header" },
                  [
                    !index
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "trendsHistoryTables__table_header-title",
                          },
                          [_vm._v(" Binance Futures Trends ")]
                        )
                      : _vm._e(),
                    _c(
                      "span",
                      { staticClass: "trendsHistoryTables__table_header-date" },
                      [_vm._v(" " + _vm._s(key) + " ")]
                    ),
                  ]
                ),
                _c("table", { staticClass: "table mb-0" }, [
                  _vm._m(0, true),
                  _c(
                    "tbody",
                    _vm._l(_vm.trendsHistoryData[key], function (row, i) {
                      return _c(
                        "tr",
                        {
                          key: i,
                          on: {
                            click: function ($event) {
                              return _vm.selectItem(row)
                            },
                          },
                        },
                        [
                          _c("td", [_vm._v(" $" + _vm._s(row.ticker) + " ")]),
                          _c(
                            "td",
                            _vm._l(
                              row.trendsStatusesFormated,
                              function (tf, index) {
                                return _c("span", { key: index }, [
                                  _vm._v(" " + _vm._s(tf.emoji) + " "),
                                ])
                              }
                            ),
                            0
                          ),
                          _c("td", [
                            _vm._v(" " + _vm._s(row.fotmatedChanged) + " "),
                          ]),
                          _c("td", { staticClass: "dateTime" }, [
                            _c("span", [
                              _vm._v(" " + _vm._s(row.last_update_date) + " "),
                            ]),
                            _c("span", { staticClass: "time" }, [
                              _vm._v(" " + _vm._s(row.last_update_time) + " "),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]
            )
          }),
          0
        )
      : _c("div", { staticClass: "trendsHistoryEmpty" }, [
          _vm._v(" " + _vm._s(_vm.$t("general.noData")) + " "),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { width: "125" } }, [_vm._v(" Ticker ")]),
        _c("th"),
        _c("th", [_vm._v(" Frame ")]),
        _c("th", [_vm._v(" Date and time ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }