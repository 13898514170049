var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cell" }, [
    _c("div", { staticClass: "top" }),
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c(
          "div",
          { staticClass: "bottom__label", class: { "no-flag": _vm.noFlag } },
          [
            _vm._t("default"),
            _vm._v(" " + _vm._s(_vm.label) + " "),
            _c(
              "transition",
              { attrs: { name: "fade", appear: "" } },
              [
                _vm.icon
                  ? _c("CIcon", {
                      staticClass: "bell",
                      attrs: { name: _vm.icon },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$emit("iconClick")
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          2
        ),
        _c("CIcon", {
          staticClass: "switcher",
          class: {
            _active: _vm.activeUp || _vm.activeDown,
            _reverse: _vm.activeUp,
          },
          attrs: { name: "arrow-strong" },
          nativeOn: {
            click: function ($event) {
              return _vm.changeSort.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }