<template>
  <div class="wrap">
    <div class="bg-dark selector">
      <v-date-picker
        mode="dateTime"
        :max-date='new Date()'
        v-model="timeComponent"
        :is-dark="$store.getters.darkMode"
        is24hr
        :masks="{
          input: 'DD.MM.YYYY HH:mm',
        }"
        :popover="{
          positionFixed: true,
          visibility: isMobile ? 'hidden' : 'hover-focus'
        }"
        :locale="$root.$i18n.locale"
        timezone="utc"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <CInput
            :value="isDesktop ? inputValue : ' '"
            :placeholder="isDesktop ? $t('trends.datePlaceholder') : ' '"
            readonly
            class="input"
            @click="isMobile ? showModal = true : null"
            v-on="inputEvents"
          >
            <!-- Добавлено для устранения увеличения масштаба страницы на iso: если размер шрифта у input меньше 16px -
                  ios увеличивает масштаб страницы при фокусировке поля ввода      -->
            <template v-if="!isDesktop" #append>
              <div
                class="form-control label-after-input"
              >
                {{ inputValue || $t('trends.datePlaceholder') }}
              </div>
            </template>
          </CInput>
        </template>
      </v-date-picker>
    </div>
    <div v-if="(time || showTfControls)" class="d-flex w-50 justify-content-between">
      <CButton
        v-if="time !== timeComponent"
        class="c-button shadow-none"
        color="primary"
        @click="setTime(timeComponent)"
      >
        <CIcon name="cil-save"/>
      </CButton>
      <CButton
        v-else-if="time"
        class="c-button shadow-none"
        color="danger"
        @click="reset"
      >
        <CIcon name="cil-x"/>
      </CButton>
      <div v-if="showTfControls" class="d-flex w-100 justify-content-center align-items-center timeFrame">
        <div
          class="mr-1 cursor-pointer"
          @click="setTF('-')">
          <CIcon name="cilMediaSkipBackward"/>
        </div>
        <v-select-input
          class="tf-select"
          v-model="timeFrame"
          :placeholder="device === 'xxl' ? 'Time Frame' :'TF'"
          :options="paramOptions"
          :clearable="false"
        >
          <template #selected-option-container="{ option }">
            <div class="vs__selected">{{ option.option.label }}</div>
          </template>
          <template v-slot:option="slotScope">
            <p class="d-flex align-items-center justify-content-center">{{ slotScope.option.label }}</p>
          </template>
        </v-select-input>
        <div
          @click="setTF('+')"
          class="ml-1 cursor-pointer">
          <CIcon name="cilMediaSkipForward"/>
        </div>
      </div>
    </div>
    <calendar-modal
      v-if="isMobile"
      :modal-title="$t('delta.datePlaceholderMobile')"
      :show="showModal"
      :value="timeComponent"
      :calendar-props="{
        maxDate: new Date(),
        is24hr: true,
        masks: {
          input: 'DD.MM.YYYY HH:mm',
        },
        timezone: 'utc',
        mode: 'dateTime'
      }"
      @save="timeComponent = $event, setTime($event)"
      @closeModal="showModal = false"
    />
  </div>
</template>

<script>
import { DatePicker } from 'v-calendar'
import {mapGetters, mapMutations, mapState} from "vuex";
import CalendarModal from "@/components/common/CalendarModal";
import VSelectInput from "@/components/VSelectInput";
import moment from 'moment'

export default {
  name: "DateSelector",
  components: {
    'v-date-picker': DatePicker,
    CalendarModal,
    VSelectInput,
  },
  props:{
    showTfControls:{
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      timeComponent: null,
      showModal: false,
      timeFrame: null,
      paramOptions: [
        { label: "30M", value: 1800 },
        { label: "1H", value: 3600 },
        { label: "2H", value: 7200 },
        { label: "4H", value: 14400 },
        { label: "12H", value: 43200 },
      ],
    }
  },
  computed: {
    ...mapState('trendsPage', ['time']),
    ...mapGetters({
      isMobile: 'mediaQuery/isMobile',
      isDesktop: 'mediaQuery/isDesktop',
      device: 'mediaQuery/device',
    }),
  },
  methods: {
    ...mapMutations('trendsPage', ['setTime']),
    reset() {
      this.timeComponent = null
      this.setTime(null)
      this.timeFrame = null
    },
    setTF(symbol){
      if(!this.timeFrame) return
      let newTime
      if(symbol === '-'){
        newTime = new Date(moment.unix((moment(this.time || new Date()).unix() - this.timeFrame.value)).format())
      }
      if(symbol === '+'){
        newTime = new Date(moment.unix((moment(this.time || new Date()).unix() + this.timeFrame.value)).format())
      }
      this.timeComponent = newTime
      this.setTime(newTime)
    },
  },
  watch: {
    timeComponent(val) {
      const newTimeUnix = moment(val).utc().unix()
      const currentTimeUnix = moment().utc().unix()

      if(newTimeUnix > currentTimeUnix){
        this.$nextTick(() => {
          this.timeComponent = new Date(moment.unix(currentTimeUnix).utc().format())
        })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  width: 10rem;
  display: flex;
  align-items: center;

  @include media-breakpoint-up(md) {
    width: 11rem;
  }

  @include media-breakpoint-up(lg) {
    width: 12rem;
    gap: 10px;
  }
}
.selector {
  flex-grow: 1;
  border-radius: .25rem;

  @include media-breakpoint-up(lg) {
    border-radius: 3px;
  }
  @include media-breakpoint-up(xl) {
    width: 50%;
  }
}
.input {
  background: transparent;
  margin: 0;
  position: relative;

  /deep/ input {
    border: none;
    background-color: transparent !important;
    font-size: 1rem;
    height: 2rem;

    @include media-breakpoint-up(lg) {
      font-size: .875rem;
      height: 2.1875rem;
    }
  }

  .label-after-input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: .75rem;
    margin: auto;
    display: flex;
    align-items: center;
    pointer-events: none;
    user-select: none;
    overflow: hidden;
    width: calc(100% - .75rem);
    font-size: .75rem;
    padding: 0;
    background: none;
    border: none;

    @include media-breakpoint-up(lg) {
      font-size: .875rem;
    }
  }
}

.c-button {
  width: 2rem;
  height: 2rem;
  padding: 0;
  flex-shrink: 0;

  @include media-breakpoint-up(lg) {
    width: 2.19rem;
    height: 2.19rem;
  }
}
.timeFrame{
  .tf-select{
    margin-bottom: 0;
    width: 100%;
    @include media-breakpoint-up(xxl) {

    }
  }

    /deep/ .form-control{
      padding-left: 5px;
    }
  }
::v-deep .tf-select .vs__search::placeholder {
  text-align: center;
}

::v-deep  .tf-select .vs__selected {
  position: absolute;
  left: 50%;

  @include media-breakpoint-up(lg) {
    transform: translateX(calc(-50% + (2vw - 20px)));
  }

  @include media-breakpoint-up(xl) {
    transform: translateX(calc(-50% + (2vw - 25px)));
  }

  @include media-breakpoint-up(xxl) {
    transform: translateX(calc(-50% + (2vw - 30px)));
  }
}

</style>
