<template>
  <detail-table
    :label="$t('trends.perfectlyFit')"
    :data="filteredStrataResult"
    :show-trend="true"
    class="detail-table"
    :class="{ '_show-watch-list': filteredStrataResult.length }"
    @row-clicked="selectItem"
  >
    <template #notify-button>
      <CButtonGroup class="d-flex btn-group-sm ml-auto strata-filter">
        <CButton
          v-for="item in filterTabList"
          :key="item.id"
          color="dark"
          :class="['cbutton', filterTabValue === item.value ? item.activeClass : '']"
          :variant="filterTabValue === item.value ? '' : 'outline'"
          @click="setFilterTab(item)"
        >
        {{ item.label }}
        </CButton>
      </CButtonGroup>
    </template>
    <template #ticker-header>
      <watch-selector
        v-if="filteredStrataResult.length"
        :showed-rows="filteredStrataResult"
        :popover-options="{
          popoverClass: 'v-tooltip popover--header',
        }"
        :is-futures="isActiveFutures"
        class="watch-selector"
      />
      Ticker
    </template>

    <template #dateTime-header>
      <detail-table-header
        key-col="dateTimeUnix"
        label="Last Update"
        :active-key-col="activeKeyCol"
        :sort-direction="sortDirection"
        @change-sort="changeSortDateTime"
      />
    </template>
  </detail-table>
</template>

<script>
import DetailTable from "@/components/trends/details/DetailTable";
import DetailTableHeader from "@/components/trends/details/DetailTableHeader";
import {mapGetters, mapMutations, mapState} from "vuex";
import WatchSelector from "@/components/screener/WatchSelector";
import { SCREENER_PAGE_FUTURES } from "@/shared/constants";

export default {
  name: "StrataTable",
  components: {
    DetailTable,
    DetailTableHeader,
    WatchSelector,
  },
  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    trendsNotifyButtonShow: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      filterTabValue: null,
      filterTabList: [
        {
          id: 1,
          label: 'L',
          value: 'up',
          activeClass: 'cbutton--activeL'
        },
        {
          id: 2,
          label: 'S',
          value: 'down',
          activeClass: 'cbutton--activeS'
        },

      ]
    }
  },
  computed: {
    ...mapState('trendsPage', ['sortStrata']),
    ...mapGetters('trendsPage', ['itemsStrataResult', 'itemsAllResult']),
    filteredStrataResult(){
      if(!this.filterTabValue)  return this.itemsStrataResult
      return this.itemsStrataResult.filter(item => item.trend === this.filterTabValue)
    },
    sortDirection() {
      return this.sortStrata?.direction
    },
    activeKeyCol() {
      return this.sortStrata?.col
    },
    isActiveFutures() {
      return this.$store.getters.getLastScreen === SCREENER_PAGE_FUTURES
    }
  },
  methods: {
    ...mapMutations('trendsPage', ['setSortStrata']),
    setFilterTab({value}){
      if (this.filterTabValue === value) {
        this.filterTabValue =  null

        return
      }

      this.filterTabValue = value
    },
    handleClickNotification() {
      this.$emit('toggleNotification', 'strata')
    },
    changeSortDateTime() {
      if (!this.sortStrata?.direction) {
        this.setSortStrata({
          col: 'dateTimeUnix',
          direction: 'down'
        })
      } else if (this.sortStrata.direction === 'down') {
        this.setSortStrata({
          col: 'dateTimeUnix',
          direction: 'up'
        })
      } else {
        this.setSortStrata({
          col: null,
          direction: null
        })
      }
    },
    selectItem(item) {
      const findItem = this.itemsAllResult.find(el => el.ticker === item.ticker)

      if (findItem) {
        if (this.prevItemAnimation) {
          if (this.prevItemAnimation.ticker !== findItem.ticker) {
            this.prevItemAnimation._classes = null
          }
        }

        findItem._classes = 'animation'
        this.prevItemAnimation = findItem
        this.$emit('selectItem', findItem)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.switcher {
  cursor: pointer;
  z-index: 1;
  opacity: 0.15;
  transition: all 250ms ease-out;

  &:hover {
    opacity: 0.3;
  }

  &._active {
    opacity: 1;
  }

  &._reverse {
    transform: rotate(180deg);
  }
}

.watch-selector {
  /deep/ .flag {
    opacity: 1 !important;
    stroke: var(--white);
    stroke-width: 2;
  }
}

.detail-table {
  &._show-watch-list {
    /deep/ {
      tr th:first-child {
        padding-left: 1.5rem;
        position: relative;
      }
    }
  }
}

.cil-bell-icon {
  width: 1rem !important;
  height: 1.3rem !important;
  margin-bottom: 0.4rem;
  cursor: pointer;

  &._blocked {
    opacity: .5;
  }

  &._active {
    color: var(--primary)
  }
}
.cbutton{
  border: 1px solid var(--dark) !important;
  height: 23px;
  line-height: 1;
  &--activeL{
    background-color: var(--success) !important;
  }
  &--activeS{
    background-color: var(--danger) !important;
  }
}
.strata-filter{
  margin-right: 2px;
}
</style>
