var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "swiper", staticClass: "swiper h-100" },
    [
      _c("div", { staticClass: "d-flex block-tabs mb-3" }, [
        _c(
          "div",
          {
            staticClass: "mr-2",
            on: {
              click: function ($event) {
                _vm.activeTab = "table1"
              },
            },
          },
          [
            _c("CIcon", {
              staticClass: "controlIcon",
              class: { "controlIcon-active": _vm.activeTab === "table1" },
              attrs: { name: "table" },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            on: {
              click: function ($event) {
                _vm.activeTab = "table2"
              },
            },
          },
          [
            _c("CIcon", {
              staticClass: "controlIcon",
              class: { "controlIcon-active": _vm.activeTab === "table2" },
              attrs: { name: "rows" },
            }),
          ],
          1
        ),
      ]),
      _vm.activeTab === "table1"
        ? _c(
            "CRow",
            {
              staticClass: "no-gutters swiper-wrapper",
              class: { "c-row": _vm.isMobile },
            },
            [
              _c(
                "CCol",
                { staticClass: "c-col swiper-slide", attrs: { col: "4" } },
                [
                  _c("only-negative", {
                    attrs: {
                      "trends-notify-button-show":
                        _vm.isUserCanShowTrendsNotifyButton,
                      enabled: _vm.isNotificationEnabled("down"),
                      loading: _vm.loading,
                    },
                    on: {
                      toggleNotification: _vm.handleToggleNotification,
                      selectItem: _vm.selectItem,
                    },
                  }),
                ],
                1
              ),
              _c(
                "CCol",
                { staticClass: "c-col swiper-slide", attrs: { col: "4" } },
                [
                  _c("strata-table", {
                    attrs: {
                      "trends-notify-button-show":
                        _vm.isUserCanShowTrendsNotifyButton,
                      loading: _vm.loading,
                      enabled: _vm.isNotificationEnabled("strata"),
                    },
                    on: {
                      toggleNotification: _vm.handleToggleNotification,
                      selectItem: _vm.selectItem,
                    },
                  }),
                ],
                1
              ),
              _c(
                "CCol",
                { staticClass: "c-col swiper-slide", attrs: { col: "4" } },
                [
                  _c("only-positive", {
                    attrs: {
                      "trends-notify-button-show":
                        _vm.isUserCanShowTrendsNotifyButton,
                      loading: _vm.loading,
                      enabled: _vm.isNotificationEnabled("up"),
                    },
                    on: {
                      selectItem: _vm.selectItem,
                      toggleNotification: _vm.handleToggleNotification,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.activeTab === "table2" ? _c("TrendHistory") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }