<template>
  <div class="d-flex align-items-center user-select-none">
    <span class="mr-2 text">
      {{label}}
    </span>
    <CIcon
      class="switcher"
      :class="{
        _active: active,
        _reverse: directionUp
      }"
      name="arrow-strong"
      @click.native="$emit('change-sort')"
    />
  </div>
</template>

<script>
export default {
  name: "DetailTableHeader",
  props: {
    keyCol: String,
    activeKeyCol: String,
    sortDirection: String,
    label: String
  },
  computed: {
    active () {
      if (!this.keyCol || !this.activeKeyCol) return false
      return this.keyCol === this.activeKeyCol
    },
    directionUp () {
      return this.sortDirection === 'up'
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
//@import "@/assets/scss/style";

.switcher {
  cursor: pointer;
  z-index: 1;
  opacity: 0.15;
  transition: all 250ms ease-out;
  &:hover {
    opacity: 0.3;
  }
  &._active {
    opacity: 1;
  }
  &._reverse {
    transform: rotate(180deg);
  }
}
</style>
