<template>
  <CCard class="c-card overflow-hidden mb-0" color="second-card-bg">
    <CCardBody class="c-card-body d-flex flex-column h-100">
      <div class="search-wrap">
        <div
          v-if="currentList && isMobile"
          class="v-select-option-list rounded-pill selectedGroup"
          :class="currentList.classes"
          :style="currentList.style"
        >
          <span>
            {{ currentList.label }}
          </span>
          <button
            type="button"
            class="vs__deselect"
            @click="removeCurrentList"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
              <path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path>
            </svg>
          </button>
        </div>
        <v-select-wrap
          class="v-select-wrap"
          :value="selectedTickers"
          :coin-list="tickerList"
          :futures-list="tickerFuturesList"
          :watch-lists="watchLists"
          :option-name="'ticker'"
          :search-keys="['label', 'labelAlt', 'ticker']"
          :reset-watch-list="openAttentionModal"
          :is-futures="isFuturesActive"
          @input="setSelectedTickersInput"
        />
      </div>

      <portal-target v-if="false" name="trends-percentage-bar" />

      <div
        :class="isMobile && 'row overflow-hidden no-gutters'"
        class="c-row"
      >
        <component
          :is="wrapComponent"
          name="fade"
          mode="out-in"
          class="row flex-grow-1 overflow-hidden no-gutters h-100"
        >
          <CCol
            v-if="(isMobile && activeTab === 1) || !isMobile"
            key="1"
            class="details-wrap col-12 col-md-7"
          >
            <details-wrap
              ref="details"
              :loadingData="loadingData"
              :settings="settings"
              @reload="getData"
              @selectItem="selectItem"
            />
          </CCol>
          <CCol
            v-if="(isMobile && activeTab === 2) || !isMobile"
            key="2"
            class="tables-group-wrap col-12 col-md-5"
          >
            <!-- <CCard color="third-card-bg">
              <CCardBody> -->
                <tables-group-item
                  ref="tablesGroupItem"
                  class="h-100"
                  :settings="settings"
                  @addCoinInNotification="addCoinInNotification"
                  @setSelectedTickersInput="setSelectedTickersInput"
                />
              <!-- </CCardBody>
            </CCard> -->
          </CCol>
        </component>
      </div>
    </CCardBody>

    <nav-btns-mobile :tabs="tabs" :active-tab.sync="activeTab" />

    <CModal
      :title="$t('screener.deleteWatchlistModal.title', { name: watchList && watchList.name ? watchList.name : '' })"
      centered
      :show="!!watchList"
      @update:show="closeAttentionModal"
    >
      <p>{{ $t('screener.deleteWatchlistModal.subtitle') }}</p>

      <template v-slot:footer>
        <div class="w-100">
          <CLoadingButtonCustom
            color="primary"
            class="mr-2"
            :loading="loadingRemoveList"
            :disabled="loadingRemoveList"
            @click.native="resetWatchList"
          >
            {{$t('general.yes')}}
          </CLoadingButtonCustom>
          <CButton
            color="dark"
            @click="closeAttentionModal"
          >
            {{$t('general.no')}}
          </CButton>
        </div>
      </template>
    </CModal>
  </CCard>
</template>

<script>
import DetailsWrap from "@/components/trends/Details.vue";
// import TablesGroup from "@/components/trends/TablesGroup"; 2 col variant removed in TTT-577
import TablesGroupItem from "@/components/trends/TablesGroupItem.vue";
import VSelectWrap from "@/components/screener/VSelectWrap.vue";
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import axios from "axios";
import settings from "@/assets/js/screenerSettings";
import {cloneDeep, uniqBy} from "lodash";
import PairItem from "@/assets/js/screenerSettings/PairItem.class";
import NavBtnsMobile from "@/components/common/NavBtnsMobile.vue";
import hideSupportMixin from "@/mixins/hideSupportMixin"
import { createWatcherExhangeAndScreen } from '@/mixins/watch-exhange-and-screen'
import { SCREENER_PAGE_FUTURES } from "@/shared/constants";

export default {
  name: "Trends",
  components: {
    VSelectWrap,
    DetailsWrap,
    NavBtnsMobile,
    TablesGroupItem
  },
  mixins: [
    hideSupportMixin,
    createWatcherExhangeAndScreen(({ getData }) => {
      getData()
    }),
  ],
  data() {
    return {
      // currentList: null,
      timer: null,
      loadingData: false,
      delay: 600000, // 10min
      settings: settings,
      watchListIndex: null,
      loadingRemoveList: false,
      activeTab: 1,
      tabs: [
        this.$t('trends.summary'),
        this.$t('trends.table')
      ]
    }
  },
  computed: {
    watchItemForRemove() {
      return this.isFuturesActive ?
        this.settings.watchListsBinancePerp[this.watchListIndex] :
        this.settings.watchLists[this.watchListIndex]
    },
    isFuturesActive() {
      return this.currentScreenAndExchange.screen === SCREENER_PAGE_FUTURES
    },
    watchLists() {
      return this.isFuturesActive
        ? this.settings.watchListsBinancePerp.map((item) => {
          item.pairs.forEach((pair) => {
            pair.ticker
          })
          return item
        })
        : this.settings.watchLists
    },
    currentList(){
      const selectedWatchList = this.watchLists.filter(item => item.pairs.length).find(item => this.settings.selectedPairs.length === item.pairs.length
      && item.pairs.every((obj2) => {
        return this.settings.selectedPairs.some((obj1) => {
          return obj1.label === obj2.label;
        });
      }))
      if(selectedWatchList){
          return {
          label: `${selectedWatchList.name} Coins:${selectedWatchList.pairs.length}`,
          classes: `${selectedWatchList.color ? `bg-${selectedWatchList.color}` : ''} _watchlist`,
          style: { background: selectedWatchList.customColor },
          pairs: selectedWatchList.pairs
        }
      }
      return undefined
    },
    ...mapGetters({
      isMobile: 'mediaQuery/isMobile',
      tickerList: 'trendsPage/tickerList',
      tickerFuturesList: 'trendsPage/tickerFuturesList',
    }),
    ...mapState('trendsPage', ['time', 'selectedTickers', 'itemsAll']),
    wrapComponent() {
      return this.isMobile ? 'transition' : 'div'
    },
    watchList() {
      return this.watchLists[this.watchListIndex]
    },
  },

  watch: {
    time() {
      this.getData()
    }
  },

  created() {
    this.getData()
    this.mapSelectedPairs()
  },

  methods: {
    mapSelectedPairs () {
      this.settings.selectedPairs.forEach(pair => {
        const label = pair.label.replace(/\.P|USDT/g, '');
        const ticker = pair.ticker.replace(/\.P|USDT/g, '');
        pair.label = label + (this.isFuturesActive ? '.P' : '');
        pair.ticker = ticker + (this.isFuturesActive ? '.P' : '');
      })
    },
    selectItem(item){
      this.activeTab = 2
      setTimeout(()=>{
        this.$refs.tablesGroupItem.scrollToElement(item)
      }, 500)
    },
    removeCurrentList(){
      this.setSelectedTickers([])
      this.settings.selectedPairs = []
    },
    async addCoinInNotification(coins){
      const trendsNotificationModal = this.$refs.details.$refs.trendNotificationComponent.$refs.trendsNotificationModal
      if(trendsNotificationModal?.hasEvents){
        coins.forEach(coin => {
          const currentCoin = trendsNotificationModal.coins.find(item => coin.ticker === item.coin_name)
          if(coin.notify){
            trendsNotificationModal.$props.trendsNotify.tfTrend.coins = trendsNotificationModal.$props.trendsNotify.tfTrend.coins
            .filter(item => item.coin_id !== currentCoin.coin_id)
          }else{
            trendsNotificationModal.$props.trendsNotify.tfTrend.coins.push(currentCoin)
          }
        })
        await trendsNotificationModal.saveNotification()
      }else{
        const coinsWithId = coins.map(coin => {
          return trendsNotificationModal?.coins?.find(item => coin.ticker === item.coin_name)
        })
        trendsNotificationModal.$props.trendsNotify.tfTrend.coins = [...trendsNotificationModal.$props.trendsNotify.tfTrend.coins,...coinsWithId]
        const query = { ...this.$route.query }
        query['openNotification'] = 1
        this.$router.replace({
          name: this.$route.name,
          query: query,
        })
      }
    },
    ...mapActions({
      getUserWatchList: 'user/getUserSettings',
    }),
    getFreshWatchLists() {
      this.getUserWatchList()
    },
    ...mapMutations('trendsPage', ['setSelectedTickers', 'setItems', 'setTickerList']),
    getData() {
      if (this.loadingData) return

      const getSettingsURL = this.isFuturesActive
        ? `/api/v3/${this.currentScreenAndExchange.exchange}_perp/screener/settings`
        : '/api/v1/screener/settings';

      this.loadingData = true
      Promise.all([
        this.$store.dispatch('trendsPage/getData'),
        axios.get(getSettingsURL)
      ])
        .then(([res1, res2]) => {
          if (res1.status < 300 && res2.status < 300) {
            const delay = res1.data.data.request_after

            this.settings.setPairs(res2?.data?.pairs)
            this.settings.setTPairs(res2?.data?.tPairs)
            this.setTickerList(this.setDisabledForOptions(this.settings.pairs, this.itemsAll))
            this.setSelectedTickers(this.setDisabledForOptions(this.settings.selectedPairs, this.itemsAll, true)
              .map(ticker => {
                const item = this.tickerList.find(el => el.label === ticker.label)
                return item || ticker
              })
            )

            this.watchLists.forEach(watchLists => {
              watchLists.pairs = this.setDisabledForOptions(watchLists.pairs, this.itemsAll, true)

              watchLists.pairs.forEach((pair, index) => {
                const item = this.tickerList.find(el => el.label === pair.label)

                if (item) {
                  watchLists.pairs.splice(index, 1, item)
                }
              })
            })

            if (this.timer) {
              clearTimeout(this.timer)
            }

            if (!delay) return
            this.timer = setTimeout(() => {
              if (this.loadingData) return
              this.getData()
            }, delay)
          }
        })
        .finally(() => {
          this.loadingData = false
        })
    },
    setDisabledForOptions(screenerArr, trendsArr, returnScreenerArrOnly = false) {
      screenerArr = cloneDeep(screenerArr).map(el => new PairItem(el))
      trendsArr = cloneDeep(trendsArr).map(el => new PairItem(el))

      screenerArr.forEach(el => {
        const pair = trendsArr.find(pair => pair.ticker === el.ticker)

        this.$set(el, 'disabledOnTrends', !pair)
      })

      if (!returnScreenerArrOnly) {
        trendsArr.forEach(el => {
          const pair = screenerArr.find(pair => pair.ticker === el.ticker)

          this.$set(el, 'disabledOnScreener', !pair)
        })
      }

      return returnScreenerArrOnly ? screenerArr : uniqBy([...screenerArr, ...trendsArr], 'ticker')
    },
  setSelectedTickersInput(arr) {
      if (arr.length) {
        this.setSelectedTickers(this.setDisabledForOptions(arr, this.itemsAll, true)
          .map(ticker => {
            const item = this.tickerList.find(el => el.label === ticker.label)
            return item || ticker
          })
        )
      } else {
        this.setSelectedTickers(arr)
      }
      this.settings.selectedPairs = this.selectedTickers
    },
    openAttentionModal (index) {
      this.watchListIndex = index
    },
    closeAttentionModal () {
      this.watchListIndex = null
    },
    resetWatchList() {
      if (!this.watchItemForRemove) return

      if (this.isFuturesActive) {
        this.settings.setWatchListsBinancePerpItem(this.watchListIndex, {
          ...this.watchItemForRemove,
          pairsString: '',
          pairs: []
        })
      } else {
        this.settings.setWatchListsItem(this.watchListIndex, {
          ...this.watchItemForRemove,
          pairsString: '',
          pairs: []
        })
      }

      this.loadingRemoveList = true;
      (this.isFuturesActive ?
        this.settings.promiseSaveWatchBinancePerpImmediately() :
        this.settings.promiseSaveWatchImmediately())
        .then(({status}) => {
          if (status < 300) {
            this.$toastr.success(this.$t('lk.settings.resetWatchlistSuccessfully', {name: this.watchItemForRemove.name}))
            this.closeAttentionModal()
            this.$refs.page?.getData()
          }
        })
        .finally(() => {
          this.loadingRemoveList = false
        })
    },
  },

  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select-option-list {
    & .vs__deselect{
      fill: var(--white);
      border: none;
      outline: none;
    }
    &._watchlist {
      color: white;
      & .vs__deselect{
        fill: white;
      }
    }

    &._disabled {
      opacity: .5;
    }
  }
.selectedGroup{
  margin: 0;
  margin-left: 5px;
  // & .vs__deselect{
  //   fill: white;
  // }
}
.details-wrap,
.tables-group-wrap {
  padding: 0 .5rem;
  height: 100%;
  flex-shrink: 1;
}

.c-row {
  margin: 0 -.3rem;
  height: calc(100% - 4.75rem);
  flex-shrink: 1;

  @include media-breakpoint-up(md) {
    height: calc(100% - 5rem);
  }

  @include media-breakpoint-up(lg) {
    height: calc(100% - 6rem);
  }

  @include media-breakpoint-up(xl) {
    height: calc(100% - 3rem);
    margin: 0 -.5rem;
  }
}

.c-card {
  height: 100%;
}

.c-card-body {
  padding: 0;

  @include media-breakpoint-up(lg) {
    padding: 1.25rem;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.search-wrap {
  height: 2.5rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  .v-select-wrap {
    background: transparent;
    flex-grow: 1;
  }

  @include media-breakpoint-up(md) {
    padding: 0 1.5rem;
    margin-bottom: 1rem;
  }

  @include media-breakpoint-up(lg) {
    padding: 0;
    height: auto;
    margin-bottom: 1rem;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 1rem;
  }
}
</style>
