<template>
  <div ref="swiper" class="swiper h-100">
    <div class="d-flex block-tabs mb-3">
      <div class="mr-2" @click="activeTab = 'table1'">
        <CIcon
          :class="{'controlIcon-active': activeTab === 'table1'}"
          class="controlIcon" name="table"
          />
      </div>
      <div @click="activeTab = 'table2'">
        <CIcon
          :class="{'controlIcon-active': activeTab === 'table2'}"
          class="controlIcon" name="rows"
          />
      </div>
    </div>
    <CRow v-if="activeTab === 'table1'" :class="{'c-row': isMobile}" class="no-gutters swiper-wrapper">
      <CCol col="4" class="c-col swiper-slide">
        <only-negative
          :trends-notify-button-show="isUserCanShowTrendsNotifyButton"
          :enabled="isNotificationEnabled('down')"
          :loading="loading"
          @toggleNotification="handleToggleNotification"
          @selectItem="selectItem"
        />
      </CCol>

      <CCol col="4" class="c-col swiper-slide">
        <strata-table
          :trends-notify-button-show="isUserCanShowTrendsNotifyButton"
          :loading="loading"
          :enabled="isNotificationEnabled('strata')"
          @toggleNotification="handleToggleNotification"
          @selectItem="selectItem"
        />
      </CCol>

      <CCol col="4" class="c-col swiper-slide">
        <only-positive
          :trends-notify-button-show="isUserCanShowTrendsNotifyButton"
          :loading="loading"
          :enabled="isNotificationEnabled('up')"
          @selectItem="selectItem"
          @toggleNotification="handleToggleNotification"
        />
      </CCol>

      <!-- <TrendsNotificationModal
        :loading="loading"
        :send-type="currentNotification.send_type"
        :modal-notification-open="isCurrentNotificationOpened"
        @close="closeNotificationModal"
        @saveNotification="handleSaveNotification"
      /> -->
    </CRow>

    <TrendHistory  v-if="activeTab === 'table2'"/>
  </div>
</template>

<script>
import OnlyPositive from "@/components/trends/details/OnlyPositive.vue";
import OnlyNegative from "@/components/trends/details/OnlyNegative.vue";
import StrataTable from "@/components/trends/details/StrataTable.vue";
import TrendHistory from "@/components/trends/details/TrendHistory.vue";
import Swiper from "swiper";
import {mapGetters} from "vuex";
import axios from "axios";
// import TrendsNotificationModal from "@/components/trends/details/TrendsNotificationModal";
import TrendsNotification from "@/assets/js/notifications/TrendsNotification.class";
import mapValues from 'lodash/mapValues'
import { SCREENER_PAGE_FUTURES } from "@/shared/constants";
import { createWatcherExhangeAndScreen } from "@/mixins/watch-exhange-and-screen";

export default {
  name: "Tables",
  components: {
    // TrendsNotificationModal,
    OnlyPositive,
    OnlyNegative,
    StrataTable,
    TrendHistory
  },
  mixins: [
    createWatcherExhangeAndScreen((context) => {
      context.initNotifications()
    }),
  ],
  data() {
    return {
      swiper: null,
      trendsNotify: [],
      currentNotification: {},
      loading: false,
      activeTab: 'table1',
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'mediaQuery/isMobile',
      isTablet: 'mediaQuery/isMobile',
      userTariffId: 'user/tariff',
      isLaptop: 'mediaQuery/isLaptop',
      isDesktop: 'mediaQuery/isDesktop',
    }),
    isCurrentNotificationOpened() {
      return this.currentNotification?.id >= 0 || false
    },
    isUserCanShowTrendsNotifyButton() {
      return this.userTariffId === 'pro'
    }
  },
  watch: {
    isMobile(val) {
      if (val) {
        this.initSwiper()
      } else if (this.swiper) {
        try {
          this.swiper.destroy()
          // eslint-disable-next-line no-empty
        } catch (e) {
        }
      }
    }
  },
  mounted() {
    // this.initNotifications()
    if (this.isMobile) {
      this.initSwiper()
    }
  },
  methods: {
    selectItem(item) {
      this.$emit('selectItem', item)
    },
    initNotifications() {
      this.loading = true
      axios.get('api/v1/tbot/trends/notification', {
        params: {
          is_futures: this.$store.getters.getLastScreen === SCREENER_PAGE_FUTURES ? 1 : 0
        }
      })
        .then(({data}) => {
          if (data?.status === true && data?.data) {
            this.trendsNotify = mapValues(data?.data, (item, key) => new TrendsNotification(item, key))
          }
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    isNotificationEnabled(val) {
      return !!this.trendsNotify?.[val]?.id
    },
    closeNotificationModal() {
      this.currentNotification = {}
    },
    handleToggleNotification(val) {
      this.currentNotification = this.trendsNotify[val]
    },
    handleSaveNotification() {
      const fd = new FormData()
      fd.append('is_futures', this.$store.getters.getLastScreen === SCREENER_PAGE_FUTURES ? 1 : 0)
      fd.append('event_name', this.currentNotification.event_name)
      if (this.currentNotification.send_type.length !== 0) {
        this.currentNotification.send_type.forEach(item => {
          fd.append('send_type[]', item)
        })
      }
      this.loading = true
      axios.post('api/v1/tbot/trends/notification', fd)
        .then(({data}) => {
          if (data?.status === true) {
            this.closeNotificationModal()
            this.$toastr.success(this.$t('general.dataChanged'))
            this.initNotifications()
          }
        })
        .catch((e) => {
          console.log(e)
          this.$toastr.error('Sending error')
        })
    },
    initSwiper() {
      if (this.$refs.swiper) {
        this.swiper = new Swiper(this.$refs.swiper, {
          slidesPerView: 1,
          observer: true,
          observeParents: true,
          observeSlideChildren: true,
          breakpoints: {
            360: {
              width: window.innerWidth
            }
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ ::-webkit-scrollbar {
  width: 4px !important;
}
.c-row {
  height: 85%;
  flex-wrap: nowrap;

  @include media-breakpoint-down(md) {
    margin: 0 -.1rem;
  }
}

.c-col {
  padding: 0 .1rem;

  @include media-breakpoint-down(sm) {
    width: 100vw;
    min-width: 100vw;
    max-width: unset;
  }

  @include media-breakpoint-up(md) {
    padding: 0 .25rem;
  }
}

.swiper {
  overflow: hidden;
}
.controlIcon{
  color: var(--secondary);
  cursor: pointer;
  &-active{
    color: var(--primary);
  }
}

.block-tabs {
  margin-left: 0;
  width: fit-content;
  @include media-breakpoint-up(sm) {
    margin-left: auto;
  }
}
.swiper-slide {
  height: 100%;

  @include media-breakpoint-up(md) {
    height: 81%;
  }

  @include media-breakpoint-up(lg) {
    height: 100%;
  }
}

.swiper-wrapper {
  @include media-breakpoint-up(lg) {
    height: 95%;
  }
}
</style>
