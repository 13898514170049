<template>
  <div class="cell">
    <div class="top">
      <div v-if="keyCol" class="control">
        <button
          class="control__item control__item_up"
          :class="{active: activeUp}"
          @click="clickEvent('up')"
        >
          <CIcon
            name="arrow-trends"
            width="0.75rem"
          />
        </button>
        <button
          class="control__item control__item_down"
          :class="{active: activeDown}"
          @click="clickEvent('down')"
        >
          <CIcon
            name="arrow-trends"
            width="0.75rem"
          />
        </button>
      </div>

      <div
        v-if="percents"
        class="percent-wrap"
        :class="[direction, {
          _hover: !isDesktop
        }]"
      >
        <div class="percent">
          <p class="percent__basic">
            {{percentStr}}
          </p>
          <p class="percent__hovered">
            {{minPercent}}%
          </p>
        </div>

        <div class="bar">
          <div
            class="bar__line"
            :style="{width: maxPercent + '%'}"
          />
        </div>
      </div>
    </div>
    <div class="bottom" :class="{success: $attrs.success}">
      <div class="bottom__label">
        {{label}}
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";

export default {
  name: "TableCellHeader",
  props: {
    keyCol: String,
    label: String,
    percents: {
      type: null,
      default: null
    }
  },
  computed: {
    ...mapState('trendsPage', ['activeKeyColumns']),
    ...mapGetters({
      isDesktop: 'mediaQuery/isDesktop'
    }),
    activeKeyCol() {
      return this.activeKeyColumns.find(el => el.col === this.keyCol)
    },
    valid () {
      const up = this.percents.up
      const down = this.percents.down

      return (up && down) >= 0
    },
    equal () {
      return this.percents.up === this.percents.down
    },
    maxPercent () {
      if (!this.valid) return null

      return Math.max(this.percents.up, this.percents.down)
    },
    minPercent () {
      if (!this.valid) return null

      return Math.min(this.percents.up, this.percents.down)
    },
    percentStr () {
      if (!this.valid) return null

      const up = this.percents.up
      const down = this.percents.down
      if (this.equal) {
        return `${up}/${down}%`
      } if (this.direction === 'up') {
        return `${up}%`
      } else {
        return `${down}%`
      }
    },
    direction () {
      const up = this.percents.up
      const down = this.percents.down

      if (!(up >= 0) || !(down >= 0)) return null

      if (up > down) return 'up'
      if (up < down) return 'down'
      return 'equal'
    },
    activeUp () {
      return this.activeKeyCol?.direction === 'up'
    },
    activeDown () {
      return this.activeKeyCol?.direction === 'down'
    }
  },
  methods: {
    ...mapMutations('trendsPage', ['setActiveCol']),
    clickEvent (direction) {
      this.setActiveCol({
        col: this.keyCol,
        direction: direction
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cell {
  text-align: center;
  //display: flex;
  //flex-direction: column;
  //justify-content: space-between;
}
.top {
  padding: 2px 4px;
  margin-bottom: 0.25rem;
}
.bottom {
  padding: 0.75rem;
  background: var(--dark);
  position: relative;
  &__label {
    z-index: 1;
    position: relative;
  }
  &.success {
    position: relative;
    color: color-yiq(theme-color("success"));
    &:before {
      z-index: 0;
      content: "";
      position: absolute;
      background: var(--success);
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
}
.control {
  margin: 0 auto;
  max-width: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__item {
    outline: none;
    border: none;
    background: none;
    padding: 2px;
    opacity: 0.3;
    line-height: 0;
    &_up {
      svg {
        color: var(--success);
      }
    }
    &_down {
      transform-origin: 50% 55%;
      transform: rotate(180deg);
      svg {
        color: var(--danger);
      }
    }
    &.active {
      opacity: 1;
    }
  }
}

.percent-wrap {
  .percent {
    display: flex;
    justify-content: center;
    font-size: 0.625rem;
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
    cursor: default;

    @include media-breakpoint-up(xl) {
      font-size: 0.6rem;
    }

    &__basic {
      margin-bottom: 0;
    }
    &__hovered {
      display: none;
      margin-bottom: 0;
    }
  }

  .bar {
    border-radius: 2px;
    background-color: var(--cell-percent-bar);
    height: 5px;
    display: flex;
    &__line {
      height: 100%;
      border-radius: 2px;
    }
  }

  &.up {
    .percent {
      &__basic {
        color: var(--success);
      }
    }
    .bar {
      &__line {
        background: var(--success);
      }
    }
  }
  &.down {
    .percent {
      &__basic {
        color: var(--danger);
      }
    }
    .bar {
      flex-direction: row-reverse;
      &__line {
        background: var(--danger);
      }
    }
  }

  &.equal {
    .percent {
      &__basic {
        color: #ADADAD;
      }
    }
    .bar {
      &__line {
        display: none;
      }
    }
  }

  &:hover,
  &._hover {
    &.up {
      .percent {
        justify-content: space-between;
        &__hovered {
          color: var(--danger);
          display: block;
        }
      }
      .bar {
        background: var(--danger);
      }
    }
    &.down {
      .percent {
        justify-content: space-between;
        flex-direction: row-reverse;
        &__hovered {
          color: var(--success);
          display: block;
        }
      }
      .bar {
        background: var(--success);
      }
    }
  }
}

</style>
