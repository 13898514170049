var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("detail-table", {
    staticClass: "detail-table",
    class: { "_show-watch-list": _vm.filteredStrataResult.length },
    attrs: {
      label: _vm.$t("trends.perfectlyFit"),
      data: _vm.filteredStrataResult,
      "show-trend": true,
    },
    on: { "row-clicked": _vm.selectItem },
    scopedSlots: _vm._u([
      {
        key: "notify-button",
        fn: function () {
          return [
            _c(
              "CButtonGroup",
              { staticClass: "d-flex btn-group-sm ml-auto strata-filter" },
              _vm._l(_vm.filterTabList, function (item) {
                return _c(
                  "CButton",
                  {
                    key: item.id,
                    class: [
                      "cbutton",
                      _vm.filterTabValue === item.value ? item.activeClass : "",
                    ],
                    attrs: {
                      color: "dark",
                      variant:
                        _vm.filterTabValue === item.value ? "" : "outline",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setFilterTab(item)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item.label) + " ")]
                )
              }),
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "ticker-header",
        fn: function () {
          return [
            _vm.filteredStrataResult.length
              ? _c("watch-selector", {
                  staticClass: "watch-selector",
                  attrs: {
                    "showed-rows": _vm.filteredStrataResult,
                    "popover-options": {
                      popoverClass: "v-tooltip popover--header",
                    },
                    "is-futures": _vm.isActiveFutures,
                  },
                })
              : _vm._e(),
            _vm._v(" Ticker "),
          ]
        },
        proxy: true,
      },
      {
        key: "dateTime-header",
        fn: function () {
          return [
            _c("detail-table-header", {
              attrs: {
                "key-col": "dateTimeUnix",
                label: "Last Update",
                "active-key-col": _vm.activeKeyCol,
                "sort-direction": _vm.sortDirection,
              },
              on: { "change-sort": _vm.changeSortDateTime },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }