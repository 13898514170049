var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "span-badge",
      class: { _active: _vm.active },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [_vm._v(" " + _vm._s(_vm.value) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }