var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    {
      staticClass: "c-card overflow-hidden mb-0",
      attrs: { color: "second-card-bg" },
    },
    [
      _c(
        "CCardBody",
        { staticClass: "c-card-body d-flex flex-column h-100" },
        [
          _c(
            "div",
            { staticClass: "search-wrap" },
            [
              _vm.currentList && _vm.isMobile
                ? _c(
                    "div",
                    {
                      staticClass:
                        "v-select-option-list rounded-pill selectedGroup",
                      class: _vm.currentList.classes,
                      style: _vm.currentList.style,
                    },
                    [
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.currentList.label) + " "),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "vs__deselect",
                          attrs: { type: "button" },
                          on: { click: _vm.removeCurrentList },
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "10",
                                height: "10",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _c("v-select-wrap", {
                staticClass: "v-select-wrap",
                attrs: {
                  value: _vm.selectedTickers,
                  "coin-list": _vm.tickerList,
                  "futures-list": _vm.tickerFuturesList,
                  "watch-lists": _vm.watchLists,
                  "option-name": "ticker",
                  "search-keys": ["label", "labelAlt", "ticker"],
                  "reset-watch-list": _vm.openAttentionModal,
                  "is-futures": _vm.isFuturesActive,
                },
                on: { input: _vm.setSelectedTickersInput },
              }),
            ],
            1
          ),
          false
            ? _c("portal-target", { attrs: { name: "trends-percentage-bar" } })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "c-row",
              class: _vm.isMobile && "row overflow-hidden no-gutters",
            },
            [
              _c(
                _vm.wrapComponent,
                {
                  tag: "component",
                  staticClass:
                    "row flex-grow-1 overflow-hidden no-gutters h-100",
                  attrs: { name: "fade", mode: "out-in" },
                },
                [
                  (_vm.isMobile && _vm.activeTab === 1) || !_vm.isMobile
                    ? _c(
                        "CCol",
                        {
                          key: "1",
                          staticClass: "details-wrap col-12 col-md-7",
                        },
                        [
                          _c("details-wrap", {
                            ref: "details",
                            attrs: {
                              loadingData: _vm.loadingData,
                              settings: _vm.settings,
                            },
                            on: {
                              reload: _vm.getData,
                              selectItem: _vm.selectItem,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  (_vm.isMobile && _vm.activeTab === 2) || !_vm.isMobile
                    ? _c(
                        "CCol",
                        {
                          key: "2",
                          staticClass: "tables-group-wrap col-12 col-md-5",
                        },
                        [
                          _c("tables-group-item", {
                            ref: "tablesGroupItem",
                            staticClass: "h-100",
                            attrs: { settings: _vm.settings },
                            on: {
                              addCoinInNotification: _vm.addCoinInNotification,
                              setSelectedTickersInput:
                                _vm.setSelectedTickersInput,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("nav-btns-mobile", {
        attrs: { tabs: _vm.tabs, "active-tab": _vm.activeTab },
        on: {
          "update:activeTab": function ($event) {
            _vm.activeTab = $event
          },
          "update:active-tab": function ($event) {
            _vm.activeTab = $event
          },
        },
      }),
      _c(
        "CModal",
        {
          attrs: {
            title: _vm.$t("screener.deleteWatchlistModal.title", {
              name:
                _vm.watchList && _vm.watchList.name ? _vm.watchList.name : "",
            }),
            centered: "",
            show: !!_vm.watchList,
          },
          on: { "update:show": _vm.closeAttentionModal },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "CLoadingButtonCustom",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            color: "primary",
                            loading: _vm.loadingRemoveList,
                            disabled: _vm.loadingRemoveList,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.resetWatchList.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("general.yes")) + " ")]
                      ),
                      _c(
                        "CButton",
                        {
                          attrs: { color: "dark" },
                          on: { click: _vm.closeAttentionModal },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("general.no")) + " ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("p", [
            _vm._v(_vm._s(_vm.$t("screener.deleteWatchlistModal.subtitle"))),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }