<template>
  <div class="percentage-bar">
    <div class="current-wrap">
      <div
        class="current"
        :style="currentValueStyle"
        :class="{
          _left: value <= -threshold,
          _right: value >= threshold
        }"
      >
        {{value}}%
      </div>
    </div>
    <div class="line">
      <div class="line__path bg-danger"></div>
      <div class="line__path bg-warning"></div>
      <div class="line__path bg-success"></div>
    </div>
    <div class="scale">
      <div class="scale__path" value="-100%"></div>
      <div class="scale__path"></div>
      <div class="scale__path"></div>
      <div class="scale__path"></div>
      <div class="scale__path" value="0"></div>
      <div class="scale__path"></div>
      <div class="scale__path"></div>
      <div class="scale__path"></div>
      <div class="scale__path" value="100%"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PercentageBar",
  data () {
    return {
      threshold: 80
    }
  },
  computed: {
    ...mapGetters('trendsPage', {
      value: 'raketaValue'
    }),
    currentValueStyle () {
      return {
        left: `${(this.value + 100) / 2}%`
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.percentage-bar {
  padding: .5rem .5rem 1rem;

  @include media-breakpoint-up(md) {
    padding: .601rem 1.5rem 1rem;
  }

  @include media-breakpoint-up(lg) {
    padding: 1.5rem 0;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 2.5rem;
    padding-bottom: 1rem;
  }
}

.line {
  height: 0.4rem;
  display: flex;
  &__path {
    flex-grow: 1;
  }
}
.scale {
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-up(xl) {
    margin-top: .25rem;
  }

  &__path {
    position: relative;
    height: 0.25rem;
    width: 1px;
    background: var(--white);
    &[value] {
      height: 0.5rem;
    }
    &:after {
      position: absolute;
      top: 0.5rem;
      left: 50%;
      transform: translateX(-50%);
      content: attr(value);
      font-size: .5rem;

      @include media-breakpoint-up(lg) {
        font-size: .625rem;
      }
    }


    @include media-breakpoint-down(lg) {
      &:first-child,
      &:last-child {
        &:after {
          transform: none;
        }
      }

      &:first-child {
        &:after {
          left: 0;
        }
      }

      &:last-child {
        &:after {
          left: unset;
          right: 0;
        }
      }
    }
  }
}
.current-wrap {
  position: relative;
}
.current {
  top: 6px;
  transform: translateX(-50%);
  position: absolute;
  border-radius: 5px;
  padding: 0 .25rem;
  background: var(--dark-contrast);
  color: var(--dark);
  font-size: .75rem;
  font-weight: 600;
  z-index: 1;

  &:after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -9px;
    content: '';
    border: 5px solid transparent;
    border-bottom-color: var(--dark-contrast);
  }

  @include media-breakpoint-down(lg) {
    &._left,
    &._right {
      &:after {
        transform: none;
        top: -4px;
        border-top: 0;
      }
    }

    &._left {
      border-top-left-radius: 0;
      transform: none;

      &:after {
        left: 0;
        border-right-color: transparent;
        border-left: 0;
      }
    }

    &._right {
      border-top-right-radius: 0;
      transform: translateX(-100%);

      &:after {
        left: unset;
        right: 0;
        border-left-color: transparent;
        border-right: 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: .875rem;
  }

  @include media-breakpoint-up(xl) {
    top: auto;
    bottom: 10px;
    padding: 0.2rem 0.4rem;

    &:after {
      bottom: -9px;
      top: auto;
      border-top-color: var(--dark-contrast);
      border-bottom-color: transparent;
    }

    &._left,
    &._right {
      &:after {
        transform: none;
        bottom: -4px;
        border-bottom: 0;
      }
    }

    &._left {
      border-bottom-left-radius: 0;
      transform: none;

      &:after {
        left: 0;
        border-right-color: transparent;
        border-left: 0;
      }
    }

    &._right {
      border-bottom-right-radius: 0;
      transform: translateX(-100%);

      &:after {
        left: unset;
        right: 0;
        border-left-color: transparent;
        border-right: 0;
      }
    }
  }
}
</style>
