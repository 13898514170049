var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100 d-flex flex-column" },
    [
      _vm.isLaptop || _vm.isDesktop
        ? _c(
            "CCard",
            { staticClass: "infoWrapper", attrs: { color: "base-card-bg" } },
            [
              _c(
                "CCardBody",
                { staticClass: "c-card-body h-100 d-flex align-items-center" },
                [
                  _c(
                    "div",
                    { staticClass: "w-50" },
                    [
                      _c("vue-speedometer", {
                        attrs: {
                          width: 220,
                          height: 140,
                          forceRender: true,
                          needleTransition: "easeElastic",
                          needleTransitionDuration: 4000,
                          segmentColors: [
                            "#F86C6B",
                            "transparent",
                            "#FFC107",
                            "transparent",
                            "#4DBD74",
                          ],
                          segments: 3,
                          value: typeof _vm.value === "number" ? _vm.value : 0,
                          minValue: -100,
                          maxValue: 100,
                          needleColor: _vm.darkMode ? "#FFFFFF" : "#000000",
                          needleHeightRatio: 0.7,
                          ringWidth: 4,
                          currentValueText: `${
                            typeof _vm.value === "number" ? _vm.value : 0
                          }%`,
                          customSegmentStops: [-100, -31, -27, 27, 31, 100],
                          customSegmentLabels: [
                            {
                              text: "-100%",
                              position: "OUTSIDE",
                            },
                            {
                              text: "",
                            },
                            {
                              text: "0%",
                              position: "OUTSIDE",
                            },
                            {
                              text: "",
                            },
                            {
                              text: "100%",
                              position: "OUTSIDE",
                            },
                          ],
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "calendar-wrap d-flex flex-column w-50" },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c("load-group", {
                            staticClass: "load-group",
                            attrs: { loadingData: _vm.loadingData },
                            on: {
                              reload: function ($event) {
                                return _vm.$emit("reload")
                              },
                            },
                          }),
                          _c("date-selector", {
                            staticClass: "date-selector",
                            attrs: { showTfControls: "" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between buttonWarrper mb-2",
                        },
                        [
                          _c("header-result", { staticClass: "flex-shrink-0" }),
                          _c("TrendsNotificationComponent", {
                            ref: "trendNotificationComponent",
                            staticClass: "w-100",
                            on: {
                              reload: function ($event) {
                                return _vm.$emit("reload")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.getBtcTicker
                        ? _c("table", { staticClass: "btc-history" }, [
                            _c("tbody", [
                              _c(
                                "tr",
                                [
                                  _c(
                                    "table-cell-ticker-content",
                                    {
                                      staticClass: "table-cell-btc-history",
                                      attrs: { item: _vm.getBtcTicker },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.getBtcTicker.ticker) +
                                          " "
                                      ),
                                      _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: {
                                                content:
                                                  _vm.$t("screener.openGraph"),
                                                container: ".c-app",
                                              },
                                              expression:
                                                "{\n                    content: $t('screener.openGraph'),\n                    container: '.c-app',\n                  }",
                                            },
                                          ],
                                          staticClass: "cil-bar-chart-btn",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.openCandlesGraph.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c("CIcon", {
                                                staticClass: "cursor-pointer",
                                                attrs: {
                                                  width: "0.75rem",
                                                  name: "cil-bar-chart",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._l(_vm.percentsKeys, function (key, i) {
                                    return _c("table-cell", {
                                      key: i,
                                      attrs: { value: _vm.getBtcTicker[key] },
                                    })
                                  }),
                                ],
                                2
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "CCard",
        {
          staticClass: "overflow-hidden mb-0 h-100",
          attrs: { color: "base-card-bg" },
        },
        [
          _c(
            "CCardBody",
            [
              _c("details-header", {
                attrs: { loadingData: _vm.loadingData },
                on: {
                  openCandlesGraph: _vm.openCandlesGraph,
                  reload: function ($event) {
                    return _vm.$emit("reload")
                  },
                },
              }),
              _c("details-tables", {
                staticClass: "details-tables",
                on: { selectItem: _vm.selectItem },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("CandlesGraphModal", {
        ref: "candles",
        attrs: {
          clientId: _vm.clientId,
          rows: _vm.rows,
          settings: _vm.settings,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }