var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isMobile
    ? _c(
        "div",
        { staticClass: "nav-wrap" },
        _vm._l(_vm.tabs, function (tab, index) {
          return _c(
            "CButton",
            {
              key: index,
              staticClass: "c-button",
              style: _vm.styleWidth,
              attrs: {
                pressed: _vm.activeTab === index + 1,
                color: "primary",
                variant: "ghost",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("update:activeTab", index + 1)
                },
              },
            },
            [_vm._v(" " + _vm._s(tab) + " ")]
          )
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }