<template>
  <div class="w-100">
    <CButton
      :class="'w-100'"
      :variant="'outline'"
      :color="'ghost-button'"
      @click="openNotificationModal"
    >
      {{ $t('lk.header.notifications') }}
    </CButton>
    <!--    <TrendsNotificationModal-->
    <!--      :loading="loading"-->
    <!--      :modal-notification-open="showNotificationModal"-->
    <!--      :trendsNotify="trendsNotify"-->
    <!--      @close="showNotificationModal = false"-->
    <!--      @saveNotification="handleSaveNotification"-->
    <!--    />-->
    <TrendsNotificationModalv2
      ref="trendsNotificationModal"
      v-if="renderNotificationModal"
      :loading="loading"
      :modal-notification-open="showNotificationModal"
      :trendsNotify="trendsNotify"
      @close="handleCloseNotificationModal"
      @saveNotification="handleSaveNotification"
    />
  </div>
</template>
<script>
import axios from "axios";
import mapValues from "lodash/mapValues";
import TrendsNotification from "@/assets/js/notifications/TrendsNotification.class";
import {mapGetters} from "vuex";
import TrendsNotificationModalv2 from "@/components/trends/TrendsNotificationModalv2.vue";
import Vue from "vue";
import { SCREENER_PAGE_FUTURES } from "@/shared/constants";
import { createWatcherExhangeAndScreen } from "@/mixins/watch-exhange-and-screen";

export default {
  name: 'TrendsNotificationComponent',
  components: {
    TrendsNotificationModalv2,
  },
  data() {
    return {
      loading: false,
      trendsNotify: {},
      showNotificationModal: false,
      renderNotificationModal: true,
      tfs: ['m30', 'm60', 'm120', 'm240', 'm720'],
    }
  },
  mixins: [
    createWatcherExhangeAndScreen((context) => {
      context.initNotifications()
    }),
  ],
  computed: {
    ...mapGetters({
      userTariffId: 'user/tariff',
    }),
    isUserCanShowTrendsNotifyButton() {
      return this.userTariffId === 'pro' || this.userTariffId === 'pro_alerts'
    },
    disabledReason() {
      if (this.$store.getters.getLastScreen === SCREENER_PAGE_FUTURES) {
        return this.$t('system.inDev')
      }
      return false
    }
  },
  mounted() {
    this.initNotifications()
    if (this.$route.query?.openNotification) {
      this.openNotificationModal()
    }
  },
  watch: {
    showNotificationModal () {
      this.renderNotificationModal = false
      this.$nextTick(() => {
        this.renderNotificationModal = true
      })
    },
    '$route.query.openNotification': {
      handler: function (val) {
        if (val) {
          this.openNotificationModal()
        }
      },
      deep: true,
    }
  },
  methods: {
    handleCloseNotificationModal() {
      this.showNotificationModal = false
      const query = {...this.$route.query}
      delete query['openNotification']
      this.$router.replace({
        name: this.$route.name,
        query: query,
      })
    },
    openNotificationModal() {
      if (this.isUserCanShowTrendsNotifyButton) {
        this.showNotificationModal = true
        const query = {...this.$route.query}
        if (!query?.openNotification) {
          query['openNotification'] = 1
          this.$router.replace({
            name: this.$route.name,
            query: query,
          })
        }
      }
    },
    saveNotifications() {
      Promise.all(Object.values(this.trendsNotify).map((item) => {
        return this.handleSaveNotification(item)
      })).then((responses) => {
      })
    },
    getFormData() {
      const formData = new FormData()
      formData.append('is_futures', this.$store.getters.getLastScreen === SCREENER_PAGE_FUTURES ? 1 : 0)
      // fd.append('event_name', item.event_name)
      // if (item.send_type.length !== 0) {
      //   item.send_type.forEach(typeItem => {
      //     fd.append('send_type[]', typeItem)
      //   })
      // }
      // for(const item in this.trendsNotify){
      //    if (this.trendsNotify[item].send_type.length !== 0) {
      //     this.trendsNotify[item].send_type.forEach(item => {
      //       fd.append('send_type[]', item)
      //     })
      //   }
      // }
      Object.values(this.trendsNotify).forEach((item, index) => {
        if (item.event_name) {
          formData.append(`event_name[${index}]`, item.event_name)
          if (item.send_type.length !== 0) {
            item.send_type.forEach((type, typeIndex) => {
              formData.append(`send_type[${index}][${typeIndex}]`, type)
            })
          }
          if (item?.coins && item.coins.length > 0) {
            item.coins.forEach(coin => {
              formData.append(`coin_ids[${index}][]`, coin.coin_id)
            })
          }
        }
      })

      return formData
    },
    async handleSaveNotification() {
      this.loading = true
      const formData = this.getFormData()
      return axios.post('api/v1/tbot/trends/notification', formData)
        //for new endpoint
        .then(({data}) => {
          if (data?.status === true) {
            if(this.showNotificationModal)this.handleCloseNotificationModal()
            this.$toastr.success(this.$t('general.dataChanged'))
            this.initNotifications()
            this.$emit('reload')
          }
        })
        .catch((e) => {
          console.log(e)
          this.$toastr.error('Sending error')
        })
    },
    initNotifications() {
      this.loading = true
      const isFutures = this.$store.getters.getLastScreen === SCREENER_PAGE_FUTURES
      axios.get('api/v1/tbot/trends/notification', {
        params: { is_futures: Number(isFutures) }
      })
        .then(({ data }) => {
          if (data?.data) {
            let tfSendType = []
            let coinsList = []
            this.trendsNotify = mapValues(data?.data, (item, key) => {
              if (this.tfs.includes(key) && item?.send_type) {
                tfSendType = item.send_type.split('|')
                coinsList = isFutures
                  ? item.coins.map(coin => ({ ...coin, coin_name: coin.coin_name + '.P' }))
                  : item.coins
              }
              return new TrendsNotification(item, key)
            })
            Vue.set(this.trendsNotify, 'tfTrend', {
              'send_type': tfSendType,
              'coins': coinsList,
            })
          }
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
  }
}
</script>
