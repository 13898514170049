var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "header-block d-flex align-items-center" }, [
    _c("span", { staticClass: "mr-2" }, [_vm._v(" ALL TF ")]),
    _c(
      "div",
      { staticClass: "d-flex header-info align-items-center" },
      [
        _c("span", { staticClass: "font-weight-bold mr-1" }, [
          _vm._v(_vm._s(_vm.raketaDown)),
        ]),
        _c("CIcon", {
          staticClass: "mr-2",
          attrs: { name: "arrow-trends-o", width: "1.1rem" },
        }),
        _c("span", { staticClass: "font-weight-bold mr-1" }, [
          _vm._v(_vm._s(_vm.raketaUp)),
        ]),
        _c("CIcon", {
          staticClass: "top",
          attrs: { name: "arrow-trends", width: "0.6rem" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }