var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      staticClass: "trends-notification-modal",
      attrs: {
        title: _vm.$t("lk.notifications.modalTitle"),
        scrollable: "",
        centered: "",
        size: "lg",
        show: _vm.modalNotificationOpen,
      },
      on: { "update:show": _vm.closeEvent },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "d-flex w-100 m-0" },
                [
                  _c(
                    "CLoadingButtonCustom",
                    {
                      staticClass: "cbtn mr-3 flex-grow-1",
                      attrs: {
                        color: "success",
                        loading: _vm.loading || _vm.isLoading,
                        disabled: _vm.loading,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.saveNotification.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("CIcon", {
                        staticClass: "my-0 mr-1",
                        attrs: { name: "cil-save" },
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("general.save")) + " "),
                    ],
                    1
                  ),
                  _c(
                    "CButton",
                    {
                      staticClass: "cbtn flex-grow-1",
                      attrs: { disabled: _vm.loading, color: "dark" },
                      on: { click: _vm.closeEvent },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("general.cancel")) + " ")]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", { staticClass: "notify-control mb-4" }, [
        _c(
          "div",
          { staticClass: "browser" },
          [
            (_vm.$store.getters["user/getPermissions"]("view_notifications") &&
              !_vm.isSubscribed) ||
            _vm.isWebNotifyDisabled
              ? _c("web-push-toggle")
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "telegram" },
          [
            _c("confirm-tg", {
              attrs: { isRequestPassed: _vm.telegramVerify },
            }),
            _c(
              "div",
              { staticClass: "d-flex align-items-center" },
              [
                !_vm.trendsBotEnabled
                  ? _c("span", { staticClass: "mr-1" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("lk.notifications.trendsBotText")) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _c("confirm-tg", {
                  attrs: {
                    isShow: !_vm.trendsBotEnabled,
                    link: _vm.trendsBotUrl,
                    isRequestPassed: !!_vm.trendsBotEnabled,
                    text: _vm.$t("lk.notifications.trendsBotLink"),
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "divider mb-4 bg-dark" }),
      _c("div", { staticClass: "top-notification mb-4" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(" Уведомления по монетам "),
          _c(
            "a",
            {
              class: "subTitle ml-3 text-primary",
              attrs: { href: _vm.trendsBotUrl, target: "_blank" },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("lk.notifications.trendsBotLinkText")) + " "
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "left" }, [
          _c("span", { staticClass: "d-flex mb-3" }, [_vm._v(" Таймфрейм: ")]),
          _c(
            "div",
            { staticClass: "badge-group" },
            [
              _c("Badge", {
                attrs: {
                  value: "30m",
                  active: _vm.trendsNotify?.m30?.id !== undefined,
                },
                on: {
                  click: function ($event) {
                    return _vm.handleTfClick("m30")
                  },
                },
              }),
              _c("Badge", {
                attrs: {
                  value: "1h",
                  active: _vm.trendsNotify?.m60?.id !== undefined,
                },
                on: {
                  click: function ($event) {
                    return _vm.handleTfClick("m60")
                  },
                },
              }),
              _c("Badge", {
                attrs: {
                  value: "2h",
                  active: _vm.trendsNotify?.m120?.id !== undefined,
                },
                on: {
                  click: function ($event) {
                    return _vm.handleTfClick("m120")
                  },
                },
              }),
              _c("Badge", {
                attrs: {
                  value: "4h",
                  active: _vm.trendsNotify?.m240?.id !== undefined,
                },
                on: {
                  click: function ($event) {
                    return _vm.handleTfClick("m240")
                  },
                },
              }),
              _c("Badge", {
                attrs: {
                  value: "12h",
                  active: _vm.trendsNotify?.m720?.id !== undefined,
                },
                on: {
                  click: function ($event) {
                    return _vm.handleTfClick("m720")
                  },
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c("span", { staticClass: "d-flex mb-3" }, [
              _vm._v(" Куда отправлять "),
            ]),
            _c(
              "CButtonGroup",
              { staticClass: "d-flex" },
              [
                _c(
                  "CButton",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: { content: _vm.getTooltipContent("telegram") },
                        expression:
                          "{ content: getTooltipContent('telegram') }",
                      },
                    ],
                    class: [
                      "cbutton",
                      { disabled: _vm.getSendTypeDisabled("telegram") },
                    ],
                    attrs: {
                      color: "dark",
                      variant:
                        _vm.getSendType("tfTrend", "telegram") >= 0
                          ? ""
                          : "outline",
                      disabled: _vm.loading,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleSendType("tfTrend", "telegram")
                      },
                    },
                  },
                  [_vm._v(" telegram ")]
                ),
                _c(
                  "CButton",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: { content: _vm.getTooltipContent("browser") },
                        expression: "{ content: getTooltipContent('browser') }",
                      },
                    ],
                    class: [
                      "cbutton",
                      { disabled: _vm.getSendTypeDisabled("browser") },
                    ],
                    attrs: {
                      color: "dark",
                      variant:
                        _vm.getSendType("tfTrend", "browser") >= 0
                          ? ""
                          : "outline",
                      disabled: _vm.loading,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleSendType("tfTrend", "browser")
                      },
                    },
                  },
                  [_vm._v(" browser ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "middle" },
          [
            _c("span", { staticClass: "d-flex mb-3" }, [
              _vm._v(" " + _vm._s(_vm.$t("trends.sendByTrend")) + " "),
            ]),
            _c(
              "CButtonGroup",
              { staticClass: "d-flex" },
              [
                _c(
                  "CButton",
                  {
                    staticClass: "cbutton",
                    attrs: {
                      disabled: _vm.loading,
                      color: "dark",
                      variant:
                        _vm.getSendType("tfTrend", "up") >= 0 ? "" : "outline",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleSendType("tfTrend", "up")
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("trends.up")) + " ")]
                ),
                _c(
                  "CButton",
                  {
                    staticClass: "cbutton",
                    attrs: {
                      disabled: _vm.loading,
                      color: "dark",
                      variant:
                        _vm.getSendType("tfTrend", "down") >= 0
                          ? ""
                          : "outline",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleSendType("tfTrend", "down")
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("trends.down")) + " ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "bottom" },
          [
            _c(
              "div",
              { staticClass: "d-flex mb-3 bottom__title" },
              [
                _c("span", [_vm._v("Отслеживаемые монеты:")]),
                _c(
                  "CButton",
                  {
                    attrs: { color: "primary" },
                    on: { click: _vm.handleSelectAllButton },
                  },
                  [_vm._v(" Выбрать все ")]
                ),
                _c(
                  "CButton",
                  {
                    attrs: { color: "light" },
                    on: { click: _vm.handleDeselectAllButton },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("general.cancelAll")) + " ")]
                ),
                _vm.isFutures
                  ? _c("CInputCheckbox", {
                      attrs: {
                        checked: _vm.perpTrendsSingleGrouppedMessagesState,
                        custom: "",
                        label: "Группировать сообщения",
                      },
                      on: {
                        "update:checked": function ($event) {
                          _vm.perpTrendsSingleGrouppedMessagesState = $event
                        },
                      },
                    })
                  : _c("CInputCheckbox", {
                      attrs: {
                        checked: _vm.trendsSingleGrouppedMessagesState,
                        custom: "",
                        label: "Группировать сообщения",
                      },
                      on: {
                        "update:checked": function ($event) {
                          _vm.trendsSingleGrouppedMessagesState = $event
                        },
                      },
                    }),
              ],
              1
            ),
            _vm.trendsNotify?.tfTrend
              ? _c("v-select", {
                  ref: "vselect",
                  staticClass: "v-select-trends flex-grow-1 flex-shrink-1",
                  attrs: {
                    filter: _vm.customFilter,
                    closeOnSelect: false,
                    options: _vm.coins,
                    getOptionKey: (option) => option.coin_id,
                    getOptionLabel: (option) => option.coin_name,
                    multiple: "",
                    placeholder: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "option",
                        fn: function (option) {
                          return [
                            _c("div", { staticClass: "rounded-pill" }, [
                              _c("span", { staticClass: "badge" }, [
                                _vm._v(" " + _vm._s(option["coin_name"]) + " "),
                              ]),
                            ]),
                          ]
                        },
                      },
                      _vm.futuresList?.length
                        ? {
                            key: "list-header",
                            fn: function () {
                              return [
                                _c("div", { staticClass: "list-header" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "v-select-option-list rounded-pill bg-base-card-bg",
                                      on: { click: _vm.setFutures },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.isFutures ? "Spot" : "Futures"
                                          ) +
                                            " " +
                                            _vm._s(
                                              `Coins:${_vm.futuresList.length}`
                                            )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.trendsNotify.tfTrend.coins,
                    callback: function ($$v) {
                      _vm.$set(_vm.trendsNotify.tfTrend, "coins", $$v)
                    },
                    expression: "trendsNotify.tfTrend.coins",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "divider mb-4 bg-dark" }),
      _c("div", { staticClass: "top-notification mb-4" }, [
        _c("div", { staticClass: "title" }, [_vm._v(" Уведомления по топам ")]),
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("span", { staticClass: "d-flex mb-3" }, [
              _vm._v(" " + _vm._s(_vm.$t("trends.onlyPositive")) + " "),
            ]),
            _c(
              "CButtonGroup",
              { staticClass: "d-flex" },
              [
                _c(
                  "CButton",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content: _vm.getTooltipContent("telegram"),
                        },
                        expression:
                          "{\n            content: getTooltipContent('telegram'),\n          }",
                      },
                    ],
                    staticClass: "cbutton",
                    class: { disabled: _vm.getSendTypeDisabled("telegram") },
                    attrs: {
                      disabled: _vm.loading,
                      color: "dark",
                      variant:
                        _vm.getSendType("up", "telegram") >= 0 ? "" : "outline",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleSendType("up", "telegram")
                      },
                    },
                  },
                  [_vm._v(" telegram ")]
                ),
                _c(
                  "CButton",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content: _vm.getTooltipContent("browser"),
                        },
                        expression:
                          "{\n            content: getTooltipContent('browser'),\n          }",
                      },
                    ],
                    staticClass: "cbutton",
                    class: { disabled: _vm.getSendTypeDisabled("browser") },
                    attrs: {
                      disabled: _vm.loading,
                      color: "dark",
                      variant:
                        _vm.getSendType("up", "browser") >= 0 ? "" : "outline",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleSendType("up", "browser")
                      },
                    },
                  },
                  [_vm._v(" browser ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c("label", { staticClass: "mb-3" }, [
              _vm._v(" " + _vm._s(_vm.$t("trends.onlyNegative")) + " "),
            ]),
            _c(
              "CButtonGroup",
              { staticClass: "d-flex" },
              [
                _c(
                  "CButton",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content: _vm.getTooltipContent("telegram"),
                        },
                        expression:
                          "{\n            content: getTooltipContent('telegram'),\n          }",
                      },
                    ],
                    staticClass: "cbutton",
                    class: { disabled: _vm.getSendTypeDisabled("telegram") },
                    attrs: {
                      disabled: _vm.loading,
                      color: "dark",
                      variant:
                        _vm.getSendType("down", "telegram") >= 0
                          ? ""
                          : "outline",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleSendType("down", "telegram")
                      },
                    },
                  },
                  [_vm._v(" telegram ")]
                ),
                _c(
                  "CButton",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content: _vm.getTooltipContent("browser"),
                        },
                        expression:
                          "{\n            content: getTooltipContent('browser'),\n          }",
                      },
                    ],
                    staticClass: "cbutton",
                    class: { disabled: _vm.getSendTypeDisabled("browser") },
                    attrs: {
                      disabled: _vm.loading,
                      color: "dark",
                      variant:
                        _vm.getSendType("down", "browser") >= 0
                          ? ""
                          : "outline",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleSendType("down", "browser")
                      },
                    },
                  },
                  [_vm._v(" browser ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "top-notification mb-4" }, [
        _c("div", { staticClass: "title" }, [_vm._v(" Четко по страте ")]),
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("span", { staticClass: "d-flex mb-3" }, [
              _vm._v(" " + _vm._s(_vm.$t("trends.up")) + " "),
            ]),
            _c(
              "CButtonGroup",
              { staticClass: "d-flex mb-2" },
              [
                _c(
                  "CButton",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content: _vm.getTooltipContent("telegram"),
                        },
                        expression:
                          "{\n            content: getTooltipContent('telegram'),\n          }",
                      },
                    ],
                    staticClass: "cbutton",
                    class: { disabled: _vm.getSendTypeDisabled("telegram") },
                    attrs: {
                      disabled: _vm.loading,
                      color: "dark",
                      variant:
                        _vm.getSendType("strataUp", "telegram") >= 0
                          ? ""
                          : "outline",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleSendType("strataUp", "telegram")
                      },
                    },
                  },
                  [_vm._v(" telegram ")]
                ),
                _c(
                  "CButton",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content: _vm.getTooltipContent("browser"),
                        },
                        expression:
                          "{\n            content: getTooltipContent('browser'),\n          }",
                      },
                    ],
                    staticClass: "cbutton",
                    class: { disabled: _vm.getSendTypeDisabled("browser") },
                    attrs: {
                      disabled: _vm.loading,
                      color: "dark",
                      variant:
                        _vm.getSendType("strataUp", "browser") >= 0
                          ? ""
                          : "outline",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleSendType("strataUp", "browser")
                      },
                    },
                  },
                  [_vm._v(" browser ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c("label", { staticClass: "mb-3" }, [
              _vm._v(" " + _vm._s(_vm.$t("trends.down")) + " "),
            ]),
            _c(
              "CButtonGroup",
              { staticClass: "d-flex" },
              [
                _c(
                  "CButton",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content: _vm.getTooltipContent("telegram"),
                        },
                        expression:
                          "{\n            content: getTooltipContent('telegram'),\n          }",
                      },
                    ],
                    staticClass: "cbutton",
                    class: { disabled: _vm.getSendTypeDisabled("telegram") },
                    attrs: {
                      disabled: _vm.loading,
                      color: "dark",
                      variant:
                        _vm.getSendType("strataDown", "telegram") >= 0
                          ? ""
                          : "outline",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleSendType("strataDown", "telegram")
                      },
                    },
                  },
                  [_vm._v(" telegram ")]
                ),
                _c(
                  "CButton",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content: _vm.getTooltipContent("browser"),
                        },
                        expression:
                          "{\n            content: getTooltipContent('browser'),\n          }",
                      },
                    ],
                    staticClass: "cbutton",
                    class: { disabled: _vm.getSendTypeDisabled("browser") },
                    attrs: {
                      disabled: _vm.loading,
                      color: "dark",
                      variant:
                        _vm.getSendType("strataDown", "browser") >= 0
                          ? ""
                          : "outline",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleSendType("strataDown", "browser")
                      },
                    },
                  },
                  [_vm._v(" browser ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.isFutures
        ? _c("CInputCheckbox", {
            attrs: {
              checked: _vm.perpTrendsGrouppedMessagesState,
              custom: "",
              label: "Группировать сообщения",
            },
            on: {
              "update:checked": function ($event) {
                _vm.perpTrendsGrouppedMessagesState = $event
              },
            },
          })
        : _c("CInputCheckbox", {
            attrs: {
              checked: _vm.trendsGrouppedMessagesState,
              custom: "",
              label: "Группировать сообщения",
            },
            on: {
              "update:checked": function ($event) {
                _vm.trendsGrouppedMessagesState = $event
              },
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }