<template>
  <!-- <CCard class="custom-card h-100" color="second-card-bg"> -->
    <div class="h-100 d-flex flex-column">
      <CCard v-if="isLaptop || isDesktop" class="infoWrapper" color="base-card-bg">
        <CCardBody class="c-card-body h-100 d-flex align-items-center">
          <div class="w-50">
            <vue-speedometer
            :width="220"
            :height="140"
            :forceRender="true"
            needleTransition="easeElastic"
            :needleTransitionDuration="4000"
            :segmentColors="['#F86C6B','transparent','#FFC107','transparent','#4DBD74']"
            :segments="3"
            :value="typeof value === 'number' ? value : 0"
            :minValue="-100"
            :maxValue="100"
            :needleColor="darkMode ? '#FFFFFF' : '#000000'"
            :needleHeightRatio="0.7"
            :ringWidth="4"
            :currentValueText='`${typeof value === "number" ? value : 0}%`'
            :customSegmentStops="[-100,-31,-27,27,31,100]"
            :customSegmentLabels="[
            {
              text: '-100%',
              position: 'OUTSIDE'
            },
            {
              text: '',
            },
            {
              text: '0%',
              position: 'OUTSIDE'
            },
            {
              text: '',
            },
            {
              text: '100%',
              position: 'OUTSIDE'
            }
            ]"
           />
          </div>
          <div class="calendar-wrap d-flex flex-column w-50">
            <div class="d-flex">
              <load-group
                class="load-group"
                :loadingData="loadingData"
                @reload="$emit('reload')"
              />
              <date-selector
                class="date-selector"
                showTfControls
                />
            </div>
            <div class="d-flex justify-content-between buttonWarrper mb-2">
              <header-result class="flex-shrink-0" />
              <TrendsNotificationComponent
                ref="trendNotificationComponent"
                class="w-100"
                @reload="$emit('reload')"
              />
            </div>
            <table v-if="getBtcTicker" class="btc-history">
              <tbody>
                <tr>
                  <table-cell-ticker-content
                    :item="getBtcTicker"
                    class="table-cell-btc-history"
                  >
                    {{ getBtcTicker.ticker }}
                    <button v-tooltip="{
                      content: $t('screener.openGraph'),
                      container: '.c-app',
                    }" type="button" class="cil-bar-chart-btn"
                      @click.stop="openCandlesGraph">
                      <div>
                        <CIcon width="0.75rem" class="cursor-pointer" name="cil-bar-chart" />
                      </div>
                    </button>
                  </table-cell-ticker-content>
                  <table-cell
                    v-for="(key, i) in percentsKeys"
                    :key="i"
                    :value="getBtcTicker[key]"
                  />
                </tr>
              </tbody>
            </table>
          </div>
        </CCardBody>
      </CCard>
      <CCard color="base-card-bg" class="overflow-hidden mb-0 h-100">
        <CCardBody>
          <details-header
            :loadingData="loadingData"
            @openCandlesGraph="openCandlesGraph"
            @reload="$emit('reload')"
          />
          <details-tables class="details-tables" @selectItem="selectItem"/>
        </CCardBody>
      </CCard>
      <CandlesGraphModal
        :clientId="clientId"
        ref="candles"
        :rows="rows"
        :settings="settings"
      />
    </div>
  <!-- </CCard> -->
</template>

<script>
import CandlesGraphModal from "@/components/screener/tables/CandlesGraphModal";
import DateSelector from "@/components/trends/DateSelector";
import {mapGetters} from "vuex";
import DetailsHeader from '@/components/trends/details/Header.vue'
import DetailsTables from '@/components/trends/details/DetailsTables.vue'
import VueSpeedometer from "vue-speedometer";
import LoadGroup from "@/components/trends/details/LoadGroup";
import TableCellTickerContent from "@/components/trends/tablesGroup/TableCellTickerContent";
import TableCell from "@/components/table/TableCell";
import HeaderResult from "@/components/trends/details/HeaderResult";
import TrendsNotificationComponent from "@/components/trends/TrendsNotificationComponent.vue";
import {CLIENT_ID_DEFAULT, CLIENT_ID_CANDLES_BINANCE_FUTURES} from "@/shared/constants";

export default {
  name: "Description",
  components: {
    CandlesGraphModal,
    TrendsNotificationComponent,
    DetailsHeader,
    DetailsTables,
    VueSpeedometer,
    LoadGroup,
    TableCellTickerContent,
    TableCell,
    HeaderResult,
    DateSelector
  },
  props: {
    loadingData: Boolean,
    settings: Object,
  },
  data(){
    return{
      loading: false,
      trendsNotify: {},
      showNotificationModal: false,
      clientId: CLIENT_ID_DEFAULT,
    }
  },
  computed:{
     ...mapGetters({
      isLaptop: 'mediaQuery/isLaptop',
      isDesktop: 'mediaQuery/isDesktop',
      userTariffId: 'user/tariff',
    }),
    ...mapGetters('trendsPage', {
      value: 'raketaValue',
      items: 'itemsAllResult'
    }),
    ...mapGetters('trendsPage', {
      getBtcTickerOnly: 'getBtcTickerOnly',
      getBtcPTickerOnly: 'getBtcPTickerOnly',
      percentsKeys: 'percentsKeys'
    }),
    ...mapGetters({
      darkMode: 'darkMode'
    }),
    rows () {
      const isSpot = !!this.getBtcTickerOnly
      const isFutures = !!this.getBtcPTickerOnly
      return this.items.filter(coin=> coin.ticker.toLowerCase() === 'btc')
      .map(item => {
        return {
          id: item.id,
          coin: {
            label: isSpot ? `${item.ticker}USDT` : isFutures ? `${item.ticker}USDT.P` : `${item.ticker}USDT`
          }
        }
      })
    },
    getBtcTicker () {
      return this.getBtcTickerOnly || this.getBtcPTickerOnly
    }
  },
  methods: {
    selectItem(item) {
      this.$emit('selectItem', item)
    },
    openCandlesGraph () {
      const isSpot = !!this.getBtcTickerOnly
      const isFutures = !!this.getBtcPTickerOnly
      const symbol = isSpot ? 'BTCUSDT' : isFutures ? 'BTCUSDT.P' : 'BTCUSDT'
      this.clientId = isFutures ? CLIENT_ID_CANDLES_BINANCE_FUTURES : CLIENT_ID_DEFAULT
      this.$refs.candles.openCandlesGraph(symbol, 2)
    }
  },
}
</script>

<style lang="scss" scoped>
//@import "@/assets/scss/style";

.custom-card {
  @include media-breakpoint-up(lg) {
    height: 100%;
    overflow-y: auto !important;
  }

  @include media-breakpoint-down(md) {
    background: transparent !important;
  }
}

.c-card-body {
  padding: 0;

  @include media-breakpoint-up(lg) {
    padding: 1.25rem;
  }
}

.buttonWarrper{
  gap: 10px;
}
.infoWrapper{
  /* min-height: 170px; */
  position: relative;
}
.details-tables {
  @include media-breakpoint-down(md) {
    margin-top: .5rem;
  }
}

.calendar-wrap {
  padding: 0 .625rem;
  margin-bottom: -.5rem;

  @include media-breakpoint-up(md) {
    padding: 0 1.5rem;
  }

  @include media-breakpoint-up(lg) {
    padding: 0;
  }
}

.block{
  padding: .375rem 0;
  @include media-breakpoint-up(lg) {
    padding: .55rem 0;
  }
}

.load-group {
  margin-right: .625rem;
  position: absolute;
  top: 10px;
  left: 10px;

  @include media-breakpoint-up(lg) {
    margin-right: 1.3rem;
  }
}
.date-selector{
  flex-grow: 1;
}

.load-group,
.date-selector,
.btc-history {
  margin-bottom: .5rem;
}

.btc-history {
  background: var(--dark);
  border-radius: .25rem;
  font-size: .75rem;
  line-height: 1;
  min-height: 2rem;
  color: var(--white);
  text-align: center;
  vertical-align: middle;

  @include media-breakpoint-up(lg) {
    border-radius: 3px;
    font-size: .875rem;
  }

  td {
    padding: 0.3rem .4rem;

    @include media-breakpoint-up(lg) {
      padding: 0.4rem;
    }

    /deep/ .icon-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.table-cell-btc-history {

  @include media-breakpoint-up(lg) {
    text-align: left;
    position: relative;
    left: 3px;
  }
}

.cil-bar-chart-btn {
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  display: inline-flex;
  margin-left: 5px
}
</style>
