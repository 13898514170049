var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loadingData
        ? _c("CSpinner", { attrs: { color: "primary", size: "sm" } })
        : _c(
            "CButton",
            {
              staticClass: "shadow-none p-0",
              on: {
                click: function ($event) {
                  return _vm.$emit("reload")
                },
              },
            },
            [_c("CIcon", { attrs: { name: "cil-reload" } })],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }