<template>
  <div>
    <CSpinner
      v-if="loadingData"
      color="primary"
      size="sm"
    />
    <CButton
      v-else
      class="shadow-none p-0"
      @click="$emit('reload')"
    >
      <CIcon name="cil-reload"/>
    </CButton>
  </div>
</template>

<script>
export default {
  name: "LoadGroup",
  props: {
    loadingData: Boolean
  }
}
</script>

<style scoped>

</style>
