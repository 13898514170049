<template>
  <div>
    <portal
      :disabled="isTablet || isMobile"
      to="trends-percentage-bar"
      class="bar"
    >
      <percentage-bar />
    </portal>

    <div v-if="isTablet || isMobile" class="calendar-wrap d-flex">
      <div class="d-flex align-items-center buttonWrapperMobile">
        <header-result class="result flex-shrink-0" />
        <TrendsNotificationComponent />
        <load-group
          class="load-group"
          :loadingData="loadingData"
          @reload="$emit('reload')"
        />
      </div>
      <date-selector class="date-selector"/>

      <table v-if="getBtcTicker" class="btc-history">
        <tbody>
          <tr>
            <table-cell-ticker-content
              :item="getBtcTicker"
              class="table-cell-btc-history"
            >
              BTC
              <button v-tooltip="{
                content: $t('screener.openGraph'),
                container: '.c-app',
              }" type="button" class="cil-bar-chart-btn"
                @click.stop="$emit('openCandlesGraph')">
                <div>
                  <CIcon width="0.75rem" class="cursor-pointer" name="cil-bar-chart" />
                </div>
              </button>
            </table-cell-ticker-content>
            <table-cell
              v-for="(key, i) in percentsKeys"
              :key="i"
              :class="getBtcTicker._cellClasses[key]"
              :value="getBtcTicker[key]"
            />
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import HeaderResult from "@/components/trends/details/HeaderResult";
import PercentageBar from "@/components/trends/details/PercentageBar";
import {mapGetters, mapState} from "vuex";
import DateSelector from "@/components/trends/DateSelector";
import LoadGroup from "@/components/trends/details/LoadGroup";
import TableCellTickerContent from "@/components/trends/tablesGroup/TableCellTickerContent";
import TableCell from "@/components/table/TableCell";
import TrendsNotificationComponent from "@/components/trends/TrendsNotificationComponent";

export default {
  name: "Header",
  components: {
    HeaderResult,
    PercentageBar,
    DateSelector,
    LoadGroup,
    TableCellTickerContent,
    TableCell,
    TrendsNotificationComponent,
  },
  props: {
    loadingData: Boolean
  },
  data() {
    return {
      showNotificationModal: false
    }
  },
  computed: {
    ...mapState('trendsPage', [
      'time'
    ]),
    ...mapGetters('trendsPage', {
      getBtcTickerOnly: 'getBtcTickerOnly',
      getBtcPTickerOnly: 'getBtcPTickerOnly',
      percentsKeys: 'percentsKeys'
    }),
    ...mapGetters({
      isTablet: 'mediaQuery/isTablet',
      isMobile: 'mediaQuery/isMobile',
    }),
    isUserCanShowTrendsNotifyButton() {
      return this.userTariffId === 'pro'
    },
    getBtcTicker () {
      return this.getBtcTickerOnly || this.getBtcPTickerOnly
    }
  },
  methods: {
    openNotificationModal(){
      if(this.isUserCanShowTrendsNotifyButton){
        this.showNotificationModal = true
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.bar {
  flex-grow: 1;

  @include media-breakpoint-up(xl) {
    margin-right: 1.3rem;
  }
}
//.result, .notification-button {
//  margin-right: .625rem;
//
//  @include media-breakpoint-up(lg) {
//    margin-right: 1.3rem;
//  }
//}
.time {
}

.header {

}

.buttonWrapperMobile {
  gap: 10px;
  margin-bottom: 1rem;
}

.calendar-wrap {
  margin-bottom: -.5rem;
  flex-direction: column;
  align-items: flex-start;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @include media-breakpoint-up(md) {
    padding: 0 1.5rem;
  }

  @include media-breakpoint-up(lg) {
    padding: 0;
  }
}

.load-group,
.date-selector {
  margin-right: .625rem;

  @include media-breakpoint-up(md) {
    //margin-left: auto;
  }

  @include media-breakpoint-up(xl) {
    margin-left: 0;
  }

  @include media-breakpoint-up(lg) {
    margin-right: 1.3rem;
  }
}

.date-selector{
  width: 13.3rem;

  @include media-breakpoint-up(sm) {
    width: 8.3rem;
  }
}
.load-group,
.date-selector,
.btc-history {
  margin-bottom: .5rem;
}

.load-group {
  margin-left: auto;
  @include media-breakpoint-up(md) {
    margin-left: 0;
  }
}

.btc-history {
  background: var(--dark);
  border-radius: .25rem;
  font-size: .75rem;
  line-height: 1;
  min-height: 2rem;
  color: var(--white);
  text-align: center;
  vertical-align: middle;


  @include media-breakpoint-up(sm) {
    margin-left: 0;
  }

  @include media-breakpoint-up(md) {
    margin-left: 0;
  }

  @include media-breakpoint-up(lg) {
    margin-left: auto;
    border-radius: 3px;
    font-size: .875rem;
  }

  td {
    padding: 0.3rem .4rem;

    @include media-breakpoint-up(lg) {
      padding: 0.4rem;
    }

    /deep/ .icon-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.table-cell-btc-history {
  position: relative;
  left: 6px;
}

.cil-bar-chart-btn {
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  display: inline-flex;
  margin-left: 5px
}

</style>
