<template>
  <div class="cell">
    <div class="top"></div>
    <div class="bottom">
      <div
      class="bottom__label"
      :class="{'no-flag': noFlag}">
        <slot></slot>
        {{label}}
        <transition name="fade" appear>
          <CIcon
          v-if="icon"
          :name="icon"
          class="bell"
          @click.native="$emit('iconClick')"
          />
        </transition>
      </div>
      <CIcon
        class="switcher"
        :class="{
          _active: activeUp || activeDown,
          _reverse: activeUp
        }"
        name="arrow-strong"
        @click.native="changeSort"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "TableCellTicker",
  props: {
    keyCol: String,
    label: String,
    noFlag: Boolean,
    icon: String,
  },
  computed: {
    ...mapState('trendsPage', ['activeKeyColumns']),
    activeKeyCol() {
      return this.activeKeyColumns.find(el => el.col === this.keyCol)
    },
    activeUp () {
      if(this.keyCol === "dateTimeUnix"){
        return this.activeKeyCol?.direction === 'down'
      }
      return this.activeKeyCol?.direction === 'up'
    },
    activeDown () {
      if(this.keyCol === "dateTimeUnix"){
        return this.activeKeyCol?.direction === 'up'
      }
      return this.activeKeyCol?.direction === 'down'
    },
  },
  methods: {
    ...mapMutations('trendsPage', ['setActiveCol']),
    changeSort () {
      if (this.activeKeyCol?.col === this.keyCol) {
        this.setActiveCol({
          col: this.keyCol,
          direction: this.keyCol === "dateTimeUnix" ? 'down' : 'up'
        })
      } else {
        this.setActiveCol({
          col: this.keyCol,
          direction: this.keyCol === "dateTimeUnix" ? 'up' : 'down'
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.bell{
  margin-left: 5px;
  cursor: pointer;
}
.cell {
  text-align: center;
  //display: flex;
  //flex-direction: column;
  //justify-content: space-between;
}
.top {
  padding: 2px 4px;
  margin-bottom: 0.25rem;
}
.bottom {
  padding: 0.75rem 0.75rem 0.75rem 0;
  background: var(--dark);
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  &__label {
    white-space: nowrap;
    margin-right: 0.5rem;
    z-index: 1;
    padding-left: 1.25rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    @include media-breakpoint-up(xl) {
      padding-left: .75rem;
    }
  }
}

.switcher {
  cursor: pointer;
  z-index: 1;
  opacity: 0.15;
  transition: all 250ms ease-out;
  &:hover {
    opacity: 0.3;
  }
  &._active {
    opacity: 1;
  }
  &._reverse {
    transform: rotate(180deg);
  }
}
.no-flag{
  padding-left: 0.75rem;
  @include media-breakpoint-up(xl) {
    padding-left: 0;
  }
}

</style>
