var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "percentage-bar" }, [
    _c("div", { staticClass: "current-wrap" }, [
      _c(
        "div",
        {
          staticClass: "current",
          class: {
            _left: _vm.value <= -_vm.threshold,
            _right: _vm.value >= _vm.threshold,
          },
          style: _vm.currentValueStyle,
        },
        [_vm._v(" " + _vm._s(_vm.value) + "% ")]
      ),
    ]),
    _vm._m(0),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "line" }, [
      _c("div", { staticClass: "line__path bg-danger" }),
      _c("div", { staticClass: "line__path bg-warning" }),
      _c("div", { staticClass: "line__path bg-success" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "scale" }, [
      _c("div", { staticClass: "scale__path", attrs: { value: "-100%" } }),
      _c("div", { staticClass: "scale__path" }),
      _c("div", { staticClass: "scale__path" }),
      _c("div", { staticClass: "scale__path" }),
      _c("div", { staticClass: "scale__path", attrs: { value: "0" } }),
      _c("div", { staticClass: "scale__path" }),
      _c("div", { staticClass: "scale__path" }),
      _c("div", { staticClass: "scale__path" }),
      _c("div", { staticClass: "scale__path", attrs: { value: "100%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }