<template>
  <div v-if="isMobile" class="nav-wrap">
    <CButton
      v-for="(tab, index) in tabs"
      :key="index"
      :pressed="activeTab === index + 1"
      :style="styleWidth"
      color="primary"
      variant="ghost"
      class="c-button"
      @click="$emit('update:activeTab', index + 1)"
    >
      {{ tab }}
    </CButton>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "NavBtnsMobile",
  props: {
    activeTab: {
      type: Number,
      required: true
    },
    tabs: {
      type: Array,
      required: true
    },
  },
  computed: {
    ...mapGetters({
      isMobile: 'mediaQuery/isMobile'
    }),
    styleWidth() {
      return {
        width: 100 / this.tabs.length + '%'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.nav-wrap {
  height: 2.25rem;
  background: var(--bg);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  .c-button {
    width: 50%;
    height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:not(.active) {
      color: var(--white);
      opacity: .6;
      transition-property: all;
    }

    &:hover,
    &:focus-visible {
      opacity: 1;
    }
  }
}
</style>
