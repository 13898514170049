var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100 d-flex flex-column" },
    [
      _c(
        "div",
        { staticClass: "table__header d-flex mb-2 align-items-center" },
        [
          _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.label))]),
          _vm._t("notify-button"),
        ],
        2
      ),
      _c(
        "CDataTable",
        _vm._g(
          {
            staticClass: "table h-100 overflow-hidden bg-second-card-bg",
            class: _vm.color,
            attrs: {
              items: _vm.data,
              fields: _vm.fields,
              hover: "",
              noItemsView: {
                noResults: _vm.$t("general.noData"),
                noItems: _vm.$t("general.noData"),
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "ticker",
                  fn: function ({ item }) {
                    return [
                      _c("td", { class: item.trend }, [
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [_c("span", [_vm._v(_vm._s(item.ticker))])]
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "ticker-header",
                  fn: function () {
                    return [_vm._t("ticker-header")]
                  },
                  proxy: true,
                },
                {
                  key: "dateTime-header",
                  fn: function () {
                    return [_vm._t("dateTime-header")]
                  },
                  proxy: true,
                },
                {
                  key: "dateTime",
                  fn: function ({ item }) {
                    return [
                      _c("td", { class: item.trend }, [
                        _c("span", { staticClass: "mr-3" }, [
                          _vm._v(_vm._s(item.dateTimeStrDate)),
                        ]),
                        _c("span", { staticClass: "time" }, [
                          _vm._v(_vm._s(item.dateTimeStrTime)),
                        ]),
                      ]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          },
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }