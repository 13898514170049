<template>
  <div class="trendsHistory">
    <div v-if="!loadingData && Object.keys(trendsHistoryData).length" class="trendsHistoryTables">
        <div
          class="trendsHistoryTables__table"
          v-for="(item,key,index) in trendsHistoryData"
          :key="key">
          <div class="trendsHistoryTables__table_header">
            <span v-if="!index" class="trendsHistoryTables__table_header-title">
              Binance Futures Trends
            </span>
            <span class="trendsHistoryTables__table_header-date">
              {{ key }}
            </span>
          </div>
          <table class="table mb-0">
            <thead>
              <tr>
                <th width='125'>
                  Ticker
                </th>
                <th>
                </th>
                <th>
                  Frame
                </th>

                <th>
                  Date and time
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(row, i) of trendsHistoryData[key]"
                :key="i"
                @click="selectItem(row)"
              >
                <td>
                  ${{ row.ticker }}
                </td>
                <td>
                  <span
                  v-for="(tf, index) of row.trendsStatusesFormated"
                  :key='index'>
                  {{ tf.emoji }}
                  </span>
                </td>
                <td>
                  {{ row.fotmatedChanged }}
                </td>

                <td class="dateTime">
                  <span>
                    {{ row.last_update_date }}
                  </span>
                  <span class="time">
                    {{ row.last_update_time }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
    <div class="trendsHistoryEmpty" v-else>
      {{$t('general.noData')}}
    </div>
  </div>

</template>

<script>
import {TrendsLogTicker} from "@/assets/js/trendsDataClasses/TickerHistory.class";
import axios from "axios";
import moment from "moment";
import { mapGetters, mapState } from 'vuex';
export default {
  name: "TrendHistory",
  props:{
    data:{
      type: Array
    }
  },
  data(){
    return{
      loadingData: false,
      trendsHistoryData: {},
      prevItemAnimation: null,
      reassignLabel:{m60: '1h', m120: '2h', m240: '4h', m720: '12h', m30: 'm30', },
    }
  },
  created() {
    this.getData(this.time)
  },
  computed:{
     ...mapGetters('trendsPage', {
      items: 'itemsAllResult',
    }),
    ...mapState('trendsPage', ['time'])
  },
  methods:{
    getData(time) {
      if (this.loadingData) return
      const params = {}

      if (time) params.date =  moment(this.time).utc().format('DD-MM-YYYY HH:mm')
      this.loadingData = true
        axios.get('/api/v2/trends/log',{
        params
      })
        .then((res) => {
          if(res?.data?.data){
            this.trendsHistoryData = {}
            for(const item in res?.data?.data){
              let timeInterval = moment(res.data.data[item].last_update).utc().format('DD.MM.YY HH:mm');
              if(this.trendsHistoryData[timeInterval]){
                this.trendsHistoryData[timeInterval].push(new TrendsLogTicker(res.data.data[item]))
              }else{
                this.trendsHistoryData[timeInterval] = []
                this.trendsHistoryData[timeInterval].push(new TrendsLogTicker(res.data.data[item]))
              }
            }
          }
        })
        .finally(() => {
          this.loadingData = false
        })
    },
    selectItem(row) {
      const analogItem = this.items.find(item => item.ticker === row.ticker)
      if (this.prevItemAnimation) {
        if (this.prevItemAnimation.ticker !== row.ticker) {
          this.prevItemAnimation._classes = null
        }
      }

      analogItem._classes = 'animation'
      this.prevItemAnimation = analogItem
    }
  },
  watch: {
    time(val) {
      this.getData(val)
    }
  },
}
</script>

<style lang="scss" scoped>
.trendsHistory{
  overflow: auto;
  height: calc(100% - 2rem);
  padding-right: 6px;
  .trendsHistoryTables{
    &__table{
      &:not(:last-child){
        margin-bottom: 24px;
      }
      &_header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-title{

        }
        &-date{
          margin-left: auto;
        }
      }
    }
  }
  .trendsHistoryEmpty{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.table{
  thead{
    tr{
      th{
        border-top: none;
        color: var(--secondary)
      }
    }
  }
  tbody{
    tr{
      &:hover{
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
  .dateTime{
    color: var(--success);
    .time{
      opacity: 0.5;
      margin-left: 5px;
    }
  }
}
</style>
