var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "portal",
        {
          staticClass: "bar",
          attrs: {
            disabled: _vm.isTablet || _vm.isMobile,
            to: "trends-percentage-bar",
          },
        },
        [_c("percentage-bar")],
        1
      ),
      _vm.isTablet || _vm.isMobile
        ? _c(
            "div",
            { staticClass: "calendar-wrap d-flex" },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex align-items-center buttonWrapperMobile",
                },
                [
                  _c("header-result", { staticClass: "result flex-shrink-0" }),
                  _c("TrendsNotificationComponent"),
                  _c("load-group", {
                    staticClass: "load-group",
                    attrs: { loadingData: _vm.loadingData },
                    on: {
                      reload: function ($event) {
                        return _vm.$emit("reload")
                      },
                    },
                  }),
                ],
                1
              ),
              _c("date-selector", { staticClass: "date-selector" }),
              _vm.getBtcTicker
                ? _c("table", { staticClass: "btc-history" }, [
                    _c("tbody", [
                      _c(
                        "tr",
                        [
                          _c(
                            "table-cell-ticker-content",
                            {
                              staticClass: "table-cell-btc-history",
                              attrs: { item: _vm.getBtcTicker },
                            },
                            [
                              _vm._v(" BTC "),
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: {
                                        content: _vm.$t("screener.openGraph"),
                                        container: ".c-app",
                                      },
                                      expression:
                                        "{\n              content: $t('screener.openGraph'),\n              container: '.c-app',\n            }",
                                    },
                                  ],
                                  staticClass: "cil-bar-chart-btn",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.$emit("openCandlesGraph")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("CIcon", {
                                        staticClass: "cursor-pointer",
                                        attrs: {
                                          width: "0.75rem",
                                          name: "cil-bar-chart",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._l(_vm.percentsKeys, function (key, i) {
                            return _c("table-cell", {
                              key: i,
                              class: _vm.getBtcTicker._cellClasses[key],
                              attrs: { value: _vm.getBtcTicker[key] },
                            })
                          }),
                        ],
                        2
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }