var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("detail-table", {
    staticClass: "detail-table",
    class: { "_show-watch-list": _vm.onlyPositiveResult.length },
    attrs: {
      label: _vm.$t("trends.onlyPositive"),
      color: "success",
      data: _vm.onlyPositiveResult,
    },
    on: { "row-clicked": _vm.selectItem },
    scopedSlots: _vm._u([
      {
        key: "ticker-header",
        fn: function () {
          return [
            _vm.onlyPositiveResult.length
              ? _c("watch-selector", {
                  staticClass: "watch-selector",
                  attrs: {
                    "showed-rows": _vm.onlyPositiveResult,
                    "popover-options": {
                      popoverClass: "v-tooltip popover--header",
                    },
                    "is-futures": _vm.isActiveFutures,
                  },
                })
              : _vm._e(),
            _vm._v(" Ticker "),
          ]
        },
        proxy: true,
      },
      {
        key: "dateTime-header",
        fn: function () {
          return [
            _c("detail-table-header", {
              attrs: {
                "key-col": "dateTimeUnix",
                label: "Last Update",
                "active-key-col": _vm.activeKeyCol,
                "sort-direction": _vm.sortDirection,
              },
              on: { "change-sort": _vm.changeSortDateTime },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }