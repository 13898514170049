import get from 'lodash/get'
import has from 'lodash/has'


export default class TrendsNotification {
  constructor(init = {}, direction) {
    this.id = get(init, 'id', undefined)
    this.event_name = get(init, 'event_name', direction)
    this.send_type = has(init, 'send_type') ? get(init, 'send_type').split('|') : []
    this.user_id = get(init, 'user_id')
    this.coins = get(init, 'coins', [])
  }
}
