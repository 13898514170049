var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cell" }, [
    _c("div", { staticClass: "top" }, [
      _vm.keyCol
        ? _c("div", { staticClass: "control" }, [
            _c(
              "button",
              {
                staticClass: "control__item control__item_up",
                class: { active: _vm.activeUp },
                on: {
                  click: function ($event) {
                    return _vm.clickEvent("up")
                  },
                },
              },
              [
                _c("CIcon", {
                  attrs: { name: "arrow-trends", width: "0.75rem" },
                }),
              ],
              1
            ),
            _c(
              "button",
              {
                staticClass: "control__item control__item_down",
                class: { active: _vm.activeDown },
                on: {
                  click: function ($event) {
                    return _vm.clickEvent("down")
                  },
                },
              },
              [
                _c("CIcon", {
                  attrs: { name: "arrow-trends", width: "0.75rem" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.percents
        ? _c(
            "div",
            {
              staticClass: "percent-wrap",
              class: [
                _vm.direction,
                {
                  _hover: !_vm.isDesktop,
                },
              ],
            },
            [
              _c("div", { staticClass: "percent" }, [
                _c("p", { staticClass: "percent__basic" }, [
                  _vm._v(" " + _vm._s(_vm.percentStr) + " "),
                ]),
                _c("p", { staticClass: "percent__hovered" }, [
                  _vm._v(" " + _vm._s(_vm.minPercent) + "% "),
                ]),
              ]),
              _c("div", { staticClass: "bar" }, [
                _c("div", {
                  staticClass: "bar__line",
                  style: { width: _vm.maxPercent + "%" },
                }),
              ]),
            ]
          )
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "bottom", class: { success: _vm.$attrs.success } },
      [
        _c("div", { staticClass: "bottom__label" }, [
          _vm._v(" " + _vm._s(_vm.label) + " "),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }