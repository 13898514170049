<template>
  <div class="h-100 d-flex flex-column">
    <div class="table__header d-flex mb-2 align-items-center">
      <p class="title">{{label}}</p>
      <slot name="notify-button" />
    </div>

    <CDataTable
      class="table h-100 overflow-hidden bg-second-card-bg"
      :class="color"
      :items="data"
      :fields="fields"
      hover
      :noItemsView="{ noResults: $t('general.noData'), noItems: $t('general.noData') }"
      v-on="$listeners"
    >
      <template #ticker="{item}">
        <td :class="item.trend">
          <div class="d-flex align-items-center">
            <!-- <CIcon
              name="arrow-trends"
              class="mr-1"
              width="0.5rem"
            /> -->
            <span>{{item.ticker}}</span>
          </div>
        </td>
      </template>

      <template #ticker-header>
        <slot name="ticker-header" />
      </template>

      <template #dateTime-header>
        <slot name="dateTime-header" />
      </template>

      <template #dateTime="{item}">
        <td :class="item.trend">
          <span class="mr-3">{{item.dateTimeStrDate}}</span>
          <span class="time">{{item.dateTimeStrTime}}</span>
        </td>
      </template>
    </CDataTable>
  </div>
</template>

<script>
export default {
  name: "DetailTable",
  props: {
    label: {
      type: String,
      default: 'Таблица'
    },
    color: {
      type: String,
      default: 'default'
    },
    data: {
      type: Array,
      default: () => ([])
    },
    showTrend: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fields: [
        { key: 'ticker', label:'Ticker', _classes: 'text-nowrap' },
        { key: 'dateTime', label:'Last Update', _classes: 'text-nowrap' }
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.table {
  &__header{
    width: calc(100% - 42px);

    @include media-breakpoint-up(md) {
      width: auto
    }
  }
  &.default {
    /deep/ thead {
      background: var(--dark);
    }
  }
  &.success {
    /deep/ thead {
      background: var(--success);
    }
  }
  &.danger {
    /deep/ thead {
      background: var(--danger);
    }
  }

  /deep/ td.up {
    color: var(--success);
    border-top: none;
    border-bottom: 1px solid var(--success);
  }
  /deep/ td.down {
    color: var(--danger);
    border-top: none;
    border-bottom: 1px solid var(--danger);
    svg {
      transform: rotate(180deg);
    }
  }

  /deep/ .table-responsive {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
  }

  /deep/ thead {
    position: sticky;
    top: -1px;
    z-index: 1;
  }

  /deep/ h2 {
    font-size: .8rem;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    @include media-breakpoint-up(lg) {
      font-size: 1rem;
    }

    svg {
      width: 1.1rem;
      flex-shrink: 0;

      @include media-breakpoint-up(lg) {
        width: 1.5rem;
      }
    }
  }

  /deep/ th,
  /deep/ td {
    font-size: .7rem;
    letter-spacing: 0.01em;

    @include media-breakpoint-up(lg) {
      font-size: .75rem;
    }
  }

  /deep/ th{
    padding-top: .4rem;
    padding-bottom: .4rem;

    @include media-breakpoint-up(xl) {
      padding: .56rem .875rem;
    }
  }

  /deep/ td{
    padding-top: .56rem;
    padding-bottom: .56rem;
    vertical-align: middle;

    @include media-breakpoint-up(xl) {
      padding: .56rem .875rem;
    }
  }

  width: calc(100% - 42px);
  margin-bottom: 75px;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
    width: auto
  }
}

.time {
  opacity: 0.7;
}

.title {
  font-size: .7rem;
  margin-bottom: 0px;
  margin-left: .625rem;
  font-weight: 500;
  letter-spacing: 0.01em;

  @include media-breakpoint-up(md) {
    font-size: .75rem;
  }

  @include media-breakpoint-up(lg) {
    margin-left: 0;
    font-size: .875rem;
  }
}
</style>
