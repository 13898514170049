<template>
  <div class="header-block d-flex align-items-center">
    <span class="mr-2">
      ALL TF
    </span>

    <div class="d-flex header-info align-items-center">
      <span class="font-weight-bold mr-1">{{raketaDown}}</span>
      <CIcon
        name="arrow-trends-o"
        width="1.1rem"
        class="mr-2"
      />

      <span class="font-weight-bold mr-1">{{raketaUp}}</span>
      <CIcon
        name="arrow-trends"
        class="top"
        width="0.6rem"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HeaderResult",
  computed: {
    ...mapGetters('trendsPage', ['raketaUp', 'raketaDown'])
  }
}
</script>

<style lang="scss" scoped>
.header-block {
  background: var(--dark);
  border-radius: .25rem;
  padding: .375rem .7rem;
  font-size: .75rem;
  line-height: 1;
  min-height: 2rem;
  

  @include media-breakpoint-up(lg) {
    border-radius: 3px;
    padding: .55rem .6rem;
    font-size: .875rem;
    justify-content: space-between;
  }
  @include media-breakpoint-up(xl) {
    width: calc(50% - 5px);
  }
}
.top {
  color: var(--success);
}
.header-info{
  @include media-breakpoint-up(xl) {
    flex: 1;
    justify-content: center;
  }
}
</style>
