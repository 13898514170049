<template>
  <span
    class="span-badge"
    :class="{'_active': active}"
    @click="$emit('click')"
  >
    {{ value }}
  </span>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: () => ''
    },
    active: {
      type: Boolean,
      default: false,
    }
  },
  name: 'Badge'
}
</script>

<style lang="scss" scoped>
.span-badge {
  white-space: nowrap;
  border-radius: 10rem;
  padding: 0.25em 0.6em;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: var(--white);
  border: 1px solid var(--primary);
  cursor: pointer;
  &_big {
    font-size: 100%;
  }
  &._active {
    background: var(--primary);
  }
}
</style>
